import React, { useEffect } from "react";
import CrossShape from "../../asstes/cross-shape.svg";
import TestimonialImg1 from "../../asstes/testmonial1.jpg";
import TestimonialImg2 from "../../asstes/testimonial2.jpg";
import TestimonialImg3 from "../../asstes/testimonial3.jpg";
import Newsletter from "../home/Newsletter";
import { Link } from "react-router-dom";
import MetaData from "../../layout/MetaData";
const Testimonial = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <MetaData title="Booming Bulls" />
      <MetaData title="Testimonial Booming Bulls" />
      {/* banner */}
      <div className="about-banner-section">
        <div className="banner-content">
          <h1 className="heading-h2">Testimonials</h1>
          <p className="para">
            <Link className="color-white" to="/">
              Home
            </Link>{" "}
            / <b>Testimonials</b>
          </p>
          <div className="cross-shape">
            <img src={CrossShape} alt="" />
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container-section mt-100">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-custom-6 col-sm-12">
            <div className="testimonial-img">
              <img src={TestimonialImg1} alt="" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-custom-6 col-sm-12">
            <div className="testimonial-img">
              <img src={TestimonialImg2} alt="" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-custom-6 col-sm-12">
            <div className="testimonial-img">
              <img src={TestimonialImg3} alt="" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-custom-6 col-sm-12">
            <div className="testimonial-img">
              <img src={TestimonialImg1} alt="" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-custom-6 col-sm-12">
            <div className="testimonial-img">
              <img src={TestimonialImg2} alt="" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-custom-6 col-sm-12">
            <div className="testimonial-img">
              <img src={TestimonialImg3} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
    </>
  );
};

export default Testimonial;
