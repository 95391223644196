import React from 'react'

const LeaveReply = () => {
    return (
        <>
            <div className="leave-reply-section mt-50">
                <h3 className="heading-h3">Leave a Reply</h3>
                <hr />
                <p className="para">Your email address will not be published. Required fields are marked *</p>
                <div className='text-area'>
                    <label htmlFor="">Comment </label>
                    <textarea name="" id="input-bg" cols="30" rows="10"></textarea>
                </div>
                <div className="name-box">
                    <div className='input'>
                        <label htmlFor="">Name</label>
                        <input type="text" name="" id="input-bg" />
                    </div>
                    <div className='input'>
                        <label htmlFor="">Email</label>
                        <input type="text" name="" id="input-bg" />
                    </div>
                </div>
                <div className="web-link">
                    <label htmlFor="input-bg">Website</label>
                    <input type="text" name="" id="input-bg" />
                </div>
                <div className="checkbox">
                    <input type="checkbox" name="tic" id="tic" />
                    <label htmlFor="tic" className='ms-2'>Save my name, email, and website in this browser for the next time I comment.</label>
                </div>
                <button className='enroll-now-btn'>Post A Comment</button>
            </div>
        </>
    )
}

export default LeaveReply
