export const NaveData = [
    {
        url: "/",
        name: "Home"
    },
    {
        url: "#",
        name: "About us",
        submenu: true,
        insideName: [
            {
                link: "/about-us",
                names: "About Booming Bulls"
            },
            {
                link: "/testimonials",
                names: "Testimonials"
            },
            {
                link: "/faq",
                names: "FAQ"
            }
        ]
    },
    {
        url: "/courses/elite-traders-live-mentorship-program-4-0",
        name: "Online Courses",
    },
    {
        url: "#",
        name: "offline Courses",
        submenu: true,
        insideName: [
            {
                link: "/hybrid-center-ahmedabad",
                names: "Ahmedabad"
            },
            {
                link: "/hybrid-center-bengaluru",
                names: "Bengaluru"
            },
            {
                link: "/hybrid-center-delhi",
                names: "Delhi",
            },
            {
                link: "/hybrid-center-gurgaon",
                names: "Gurgaon",
            },
            {
                link: "/hybrid-center-hyderabad",
                names: "Hyderabad",
            },
            {
                link: "/hybrid-center-indore",
                names: "Indore",
            },
            {
                link: "/hybrid-center-jaipur",
                names: "Jaipur",
            },
            {
                link: "/hybrid-center-kolkata",
                names: "Kolkata",
            },
            {
                link: "/hybrid-center-mumbai",
                names: "Mumbai",
            },
            {
                link: "/hybrid-center-nagpur",
                names: "Nagpur",
            },
            {
                link: "/hybrid-center-pune",
                names: "Pune",
            },
            {
                link: "/hybrid-center-surat",
                names: "Surat",
            },

        ]
    },
    {
        url: "/resources",
        name: "Resources"
    },
    {
        url: "/blog",
        name: "Blog"
    },
    {
        url: "/contact-us",
        name: "Contact us"
    },
]