import React, { useEffect } from "react";
import PackageBook from "../../asstes/package-book.png";
import { MdOutlineComment } from "react-icons/md";
import { GiFlexibleStar } from "react-icons/gi";
import { RiFacebookBoxLine } from "react-icons/ri";
import { BsBarChart } from "react-icons/bs";
import { BiLike } from "react-icons/bi";
import { LuFilePieChart } from "react-icons/lu";
import { CiCircleQuestion } from "react-icons/ci";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
const WhyTrustUs = () => {
  useEffect(() => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  }, []);
  return (
    <>
      {/* why trust us */}
      <div className="learn-experince-section">
        <div className="container-section center-box start-box">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="element-conatiner">
                <img src={PackageBook} alt="" />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="element-conatiner elmb">
                <h2 className="heading-h2 color-white">Why Trust Us</h2>
                <p className="para color-white">
                  Booming Bulls Academy focuses on helping future traders
                  understand the stock market better with training in
                  fundamental and technical analysis, and a hands-on approach
                  through live trading. <br />
                  During the course duration students will be thoroughly
                  equipped with the ability to create their own Trading Plan, as
                  well as Entry and Exit Strategies under the guidance of our
                  experienced faculty. Paired with Booming Bulls Academy’s
                  resources and access to regular stock market updates will not
                  only enable students to stand on their own two feet, but also
                  to make a mark as traders.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="elementor-shape elementor-shape-bottom"
          data-shape="curve"
          data-negative="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
          >
            <path
              class="elementor-shape-fill"
              fill="#fff"
              d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
            ></path>
          </svg>
        </div>
      </div>

      {/* Benefits From Course */}
      <div className="container-section mt-50">
        <h2 className="heading-h2 text-center">Benefits From Course</h2>
        <div className="row mt-50">
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="benefit-course-box">
              <span>
                <MdOutlineComment />
              </span>
              <p className="para">Revision Classes Upto 3 Months</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="benefit-course-box">
              <span>
                <GiFlexibleStar />
              </span>
              <p className="para">5 Star Trading Setup</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="benefit-course-box">
              <span>
                <RiFacebookBoxLine />
              </span>
              <p className="para">
                Booming Bulls Facebook Daily Stock Selection Group
              </p>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 mb-4">
            <div className="benefit-course-box">
              <span>
                <BsBarChart />
              </span>
              <p className="para">Trading System Development</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="benefit-course-box">
              <span>
                <LuFilePieChart />
              </span>
              <p className="para">Trading Psychology</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="benefit-course-box">
              <span>
                <BiLike />
              </span>
              <p className="para">Ultimate Trader's Checklist's</p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 mb-4">
            <div className="benefit-course-box">
              <span>
                <CiCircleQuestion />
              </span>
              <p className="para">FnO FAQ's Sessions</p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 mb-4">
            <div className="benefit-course-box">
              <span>
                <IoShieldCheckmarkOutline />
              </span>
              <p className="para">And Much More...</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyTrustUs;
