import React, { useEffect } from "react";
import AbhirupImg from "../../asstes/abhirup-sir.png";
const OurTeam = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="our-team-section mt-100-back">
        <div className="container-section">
          <p className="color-red text-center">
            LET'S MEET THE PEOPLE BEHIND BOOMING BULLS
          </p>
          <h2 className="heading-h2 text-center">Our Team</h2>

          <div className="row mt-50">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="our-team-box">
                <div className="our-team-img">
                  <img src={AbhirupImg} alt="" />
                </div>
                <div className="our-team-content">
                  <h3 className="heading-h3">Abhirup Sekhri</h3>
                  <p className="para fw">Abhirup Sekhri</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="our-team-box">
                <div className="our-team-img">
                  <img src={AbhirupImg} alt="" />
                </div>
                <div className="our-team-content">
                  <h3 className="heading-h3">Abhirup Sekhri</h3>
                  <p className="para fw">Abhirup Sekhri</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="our-team-box">
                <div className="our-team-img">
                  <img src={AbhirupImg} alt="" />
                </div>
                <div className="our-team-content">
                  <h3 className="heading-h3">Abhirup Sekhri</h3>
                  <p className="para fw">Abhirup Sekhri</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="our-team-box">
                <div className="our-team-img">
                  <img src={AbhirupImg} alt="" />
                </div>
                <div className="our-team-content">
                  <h3 className="heading-h3">Abhirup Sekhri</h3>
                  <p className="para fw">Abhirup Sekhri</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="our-team-box">
                <div className="our-team-img">
                  <img src={AbhirupImg} alt="" />
                </div>
                <div className="our-team-content">
                  <h3 className="heading-h3">Abhirup Sekhri</h3>
                  <p className="para fw">Abhirup Sekhri</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="our-team-box">
                <div className="our-team-img">
                  <img src={AbhirupImg} alt="" />
                </div>
                <div className="our-team-content">
                  <h3 className="heading-h3">Abhirup Sekhri</h3>
                  <p className="para fw">Abhirup Sekhri</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="our-team-box">
                <div className="our-team-img">
                  <img src={AbhirupImg} alt="" />
                </div>
                <div className="our-team-content">
                  <h3 className="heading-h3">Abhirup Sekhri</h3>
                  <p className="para fw">Abhirup Sekhri</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="our-team-box">
                <div className="our-team-img">
                  <img src={AbhirupImg} alt="" />
                </div>
                <div className="our-team-content">
                  <h3 className="heading-h3">Abhirup Sekhri</h3>
                  <p className="para fw">Abhirup Sekhri</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
