import React, { useEffect } from "react";
import CrossShape from "../../asstes/cross-shape.svg";
import { Link } from "react-router-dom";
import Zerodha from "../../asstes/Zarodha.png";
import Upstox from "../../asstes/Upstox.png";
import Exness from "../../asstes/Exness.png";
import "./resources.css";
import RecommendBook from "./RecommendBook";
import MetaData from "../../layout/MetaData";
const Resources = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <MetaData title="Booming Bulls" />
      <MetaData title="Resource Booming Bulls" />
      {/* banner section */}
      <div className="about-banner-section">
        <div className="banner-content">
          <h1 className="heading-h2">Resources</h1>
          <p className="para">
            <Link className="color-white" to="/">
              Home
            </Link>{" "}
            / <b>Resources</b>
          </p>
          <div className="cross-shape">
            <img src={CrossShape} alt="" />
          </div>
        </div>
      </div>

      {/* Recommended Brokers for Stock Market */}
      <div className="container-section mt-50">
        <h2 className="heading-h2 text-center">
          Recommended Brokers for Stock Market
        </h2>
        <div className="row mt-5">
          <div className="col-md-6 col-sm-12">
            <div className="our-borker-container">
              <img src={Zerodha} alt="" />
              <p className="para">
                OPEN YOUR DEMAT ACCOUNT IN ZERODHA:{" "}
                <Link
                  to="https://bit.ly/3gyhIWN"
                  target="_blank"
                  className="color-tomato"
                >
                  {" "}
                  https://bit.ly/3gyhIWN
                </Link>{" "}
                and send your ID to{" "}
                <a
                  href="mailto:demat@boomingbulls.com"
                  className="color-tomato"
                >
                  demat@boomingbulls.com
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="our-borker-container">
              <img src={Upstox} alt="" />
              <p className="para">
                OPEN YOUR DEMAT ACCOUNT IN UPSTOX:
                <Link
                  to="https://upstox.com/open-account/?f=LPQY"
                  target="_blank"
                  className="color-tomato"
                >
                  {" "}
                  https://upstox.com/open-account/?f=LPQY
                </Link>{" "}
                and send your ID to{" "}
                <a
                  href="mailto:demat@boomingbulls.com"
                  className="color-tomato"
                >
                  demat@boomingbulls.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-section mt-50">
        <h2 className="heading-h2 text-center">Recommended Broker for Forex</h2>
        <div className="row mt-5">
          <div className="col-md-6 col-sm-12">
            <div className="our-borker-container">
              <img src={Exness} alt="" />
              <p className="para">
                Exness:
                <Link
                  to="https://one.exnesstrack.com/a/senizhs8qo"
                  target="_blank"
                  className="color-tomato"
                >
                  {" "}
                  https://one.exnesstrack.com/a/senizhs8qo
                </Link>{" "}
                and send ID to
                <a
                  href="mailto:forex@boomingbulls.com"
                  className="color-tomato"
                >
                  forex@boomingbulls.com
                </a>
              </p>
            </div>
          </div>
        </div>

        <hr className="mt-4" />
        <p className="para text-center px-5 mt-5">
          Everyone in this world should learn continuously, Whether you trade or
          not you have to sharpen your skills. Now if you Trade advanced level
          or basic you have to feed your mind with positive & knowledge specific
          data. The key areas one should focus on are Trading Knowledge, Self
          Development, Emotional Discipline, Risk Management & Keeping things
          Simple. We are providing our best recommendations here. If you are new
          to trading or you are an experienced trader you will fall in love with
          these books.
        </p>
      </div>

      {/* Books We Recommend */}
      <RecommendBook />
    </>
  );
};

export default Resources;
