import React, { useEffect } from "react";
import "./offlicencenter.css";
import OfflineCenterImg from "../../asstes/Web-Ahmedabad.jpg";
import BoomingImg from "../../asstes/Booming-Bulls.png";

import { FaUser } from "react-icons/fa";
import WhyJoinUs from "./WhyJoinUs";
import WhyTrustUs from "./WhyTrustUs";
import OurStudent from "./OurStudent";
import Contact from "../home/Contact";
import MetaData from "../../layout/MetaData";
const AhmedabadCenter = ({ title, className, contactdetails, number }) => {
  useEffect(() => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  }, []);
  return (
    <>
      <MetaData title="Booming Bulls" />
      <MetaData title={`${title} Booming Bulls`} />
      <div className={`offline-center-banner ${className}`}>
        {/* <img src={OfflineCenterImg} alt="" /> */}
        <div className="offline-center-banner-content">
          <h1 className="heading-h1">Learn Trading Offline In {title}</h1>
          <p>
            We are now in your City!! Get the offline learning experience and
            learn to trade in the most simplified way with India’s most loved
            trading mentor Anish Singh Thakur!
          </p>
          <button className="enroll-now-btn">
            {" "}
            <FaUser /> ENROLL NOW
          </button>
        </div>
      </div>

      {/* ABOUT BOOMING BULLS */}
      <div className="container-section mt-50">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p className="color-red">ABOUT BOOMING BULLS</p>
            <h2 className="heading-h2">
              Welcome to Booming Bulls Hybrid Center {title}
            </h2>
            <p className="para">
              Booming Bulls is an initiative for the young generation interested
              in making more, willing to step forward and be free from financial
              situations. We provide knowledge based on self-learning,
              experience, and theoretical implications on the market. We give
              you the path, exact system, and psychology needed to perform well
              and earn consistent profits in the stock market. With our concepts
              and with our experience in the market we have developed such a
              course which is easily understandable.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="img-box">
              <img src={BoomingImg} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* Counsellor */}
      <div className="container-section mt-50">
        <div className="counsellor-box">
          <h2 className="heading-h2">
            Get Your Queries Solved. Talk To Our{" "}
            <span className="color-tomato">Counsellor</span>
          </h2>
          <div>
            <button className="enroll-now-btn">
              <FaUser /> ENROLL NOW
            </button>
          </div>
        </div>
      </div>

      {/* Why Join Us */}
      <WhyJoinUs />

      {/* Why Trust Us */}
      <WhyTrustUs />

      {/* our student */}
      <OurStudent contactdetails={contactdetails} number={number} />
    </>
  );
};

export default AhmedabadCenter;
