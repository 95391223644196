import React, { useEffect } from "react";
import "./about.css";
import CrossShape from "../../asstes/cross-shape.svg";
import Shape33 from "../../asstes/shape33.png";

import { FaUser } from "react-icons/fa";

import WhyChoose from "../../asstes/why-choose-up.jpg";
import { PiStudentFill } from "react-icons/pi";
import { GiBackwardTime } from "react-icons/gi";
import { TbChartRadar } from "react-icons/tb";
import About1 from "../../asstes/about-1.png";
import About2 from "../../asstes/about-2.png";
import About3 from "../../asstes/about-3.png";
import About4 from "../../asstes/about-4.png";
import Shape1 from "../../asstes/shape2-1.png";
import Shape2 from "../../asstes/shape23.png";
import Shape3 from "../../asstes/shape24.png";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import CounterBg from "../../asstes/funfacts-shape.png";
import AboutUserImg from "../../asstes/about-user-img.jpg";
import DotShape from "../../asstes/dot-shape.png";
import { IoMdPlay } from "react-icons/io";
import Shape24 from "../../asstes/shape24.png";
import Shape25 from "../../asstes/shape2-1.png";
import AshisImg from "../../asstes/ashis.jpg";
import Shape23 from "../../asstes/shape23.png";

import { PiBrainThin } from "react-icons/pi";
import { BsShieldCheck } from "react-icons/bs";
import { IoIosDesktop } from "react-icons/io";
import OurTeam from "./OurTeam";
import Contact from "../home/Contact";

import Shape34 from "../../asstes/shape34.png";
import Logo1 from "../../asstes/Logo-01.png";
import Logo2 from "../../asstes/Logo-02.png";
import Logo3 from "../../asstes/Logo-03.png";
import Logo4 from "../../asstes/Logo-04.png";
import Logo5 from "../../asstes/Logo-05.png";
import Logo6 from "../../asstes/Logo-06.png";
import MetaData from "../../layout/MetaData";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <MetaData title="Booming Bulls" />
      <MetaData title="About Us Booming Bulls" />
      <div className="about-banner-section">
        <div className="banner-content">
          <h1 className="heading-h2">About Us</h1>
          <p className="para">
            <Link className="color-white" to="/">
              Home
            </Link>{" "}
            / <b>About Us</b>
          </p>
          <div className="cross-shape">
            <img src={CrossShape} alt="" />
          </div>
          <div className="shape33">
            <img src={Shape33} alt="" />
          </div>
        </div>
      </div>

      {/* About */}
      <div className="container-section mt-100">
        <div className="about-us-section">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="about-welcome-image">
                    <div className="images">
                      <img src={About1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="about-welcome-image">
                    <div className="images">
                      <img src={About2} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="about-welcome-image">
                    <div className="images">
                      <img src={About3} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="about-welcome-image">
                    <div className="images">
                      <img src={About4} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">

              <div className="about-welcome-content elmt ">
                <p className="para color-red">ABOUT US</p>
                <h2 className="heading-h2">Welcome to Booming Bulls Academy</h2>
                <p className="para">
                  Booming Bulls is an initiative for the young generation
                  interested in making more, willing to step forward and be free
                  from financial situations. We provide knowledge based on
                  self-learning, experience, and theoretical implications on the
                  market.
                </p>
                <p className="para">
                  In general, trading is considered a business not suitable for
                  everyone, but this is just a hoax which we clear by letting
                  you learn about the strategies that can provide a good profit.
                </p>
                <div className="why-choose-ul">
                  <li>
                    <span>
                      {" "}
                      <i>
                        <PiStudentFill />
                      </i>
                      Revision Classes
                    </span>
                  </li>
                  <li>
                    <span>
                      <i>
                        <GiBackwardTime />
                      </i>{" "}
                      3 Months Support
                    </span>
                  </li>
                  <li>
                    <span>
                      {" "}
                      <i>
                        <TbChartRadar />
                      </i>
                      Trading System
                    </span>
                  </li>
                </div>
                <div class="flex-1 mt-3">
                  <button class="button button-mat btn--8">
                    <span class="psuedo-text">
                      {" "}
                      <span>
                        <FaUser />
                      </span>
                      Enroll Now
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="about-shape-1">
            <img src={Shape1} alt="" />
          </div>
          <div className="about-shape-2">
            <img src={Shape2} alt="" />
          </div>
          <div className="about-shape-3">
            <img src={Shape3} alt="" />
          </div>
        </div>
      </div>

      <div className="why-choose-us-section mt-100 center-box">
        <div className="container-section">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <p className="color-red">WHY CHOOSE US</p>
              <h2 className="heading-h2">
                What Makes Us Different from the Competiton?
              </h2>
              <p className="para">
                We focus on helping you understand markets better & learn to
                analyze charts on your own & not just sell you a strategy that
                is not enough on its own. We help you design your Trading Plan,
                which is crucial; therefore, you will always have an Entry Plan
                & Exit Plan. Being Unbiased in the Stock Market is a Blessing;
                always have a Neutral Mindset. A calm mind continuously learns
                to keep the peace even in a situation of chaos, "RESERVE YOUR
                CHAOS".
              </p>
              <p className="para">
                Not a member yet?{" "}
                <Link to="/register" style={{ color: "#dd3333" }}>
                  Register now
                </Link>
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-counter-container">
                <div className="about-counter-box">
                  <div className="about-counter-content">
                    <img src={CounterBg} alt="" />
                    <h2>
                      <CountUp delay={2} end={2} />
                      <b>M</b>
                    </h2>
                    <p>YOUTUBE SUBSCRIBERS</p>
                  </div>
                </div>
                <div className="about-counter-box">
                  <div className="about-counter-content">
                    <img src={CounterBg} alt="" />
                    <h2>
                      <CountUp delay={2} end={27000} />
                      <b>+</b>
                    </h2>
                    <p>STUDENTS MENTORED</p>
                  </div>
                </div>
                <div className="about-counter-box">
                  <div className="about-counter-content">
                    <img src={CounterBg} alt="" />
                    <h2>
                      <CountUp delay={2} end={1000} />
                      <b>+</b>
                    </h2>
                    <p>SESSIONS FINISHED</p>
                  </div>
                </div>
                <div className="about-counter-box">
                  <div className="about-counter-content">
                    <img src={CounterBg} alt="" />
                    <h2>
                      <CountUp delay={2} end={4} />.
                      <CountUp delay={2} end={6} />
                    </h2>
                    <p>GOOGLE RATING</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-user-container">
          <div className="img-box  round-puls">
            <img src={AboutUserImg} alt="" />
            <a
              href="https://www.youtube.com/watch?v=vP_wawbgYJE"
              class="video-btn tutorial__pulsar"
              rel="nofollow noopener"
              target="_blank"
            >
              <span>
                <IoMdPlay />
              </span>
            </a>
          </div>
          <div className="dot-shape">
            <img src={DotShape} alt="" />
          </div>
          <div className="cross-shape">
            <img src={CrossShape} alt="" />
          </div>
          <div className="about-shape-4">
            <img src={Shape2} alt="" />
          </div>
          <div className="about-shape-5">
            <img src={Shape3} alt="" />
          </div>
        </div>
      </div>

      {/* Our Values */}

      <div className="container-section mt-50">
        <div className="our-value-section">
          <div className="popular-content">
            <p className="color-red">EDUCATION FOR EVERYONE</p>
            <h2 className="heading-h2">Our Values</h2>
            <p className="para">
              Finding your own space and utilizing better learning options can
              result faster than the traditional ways. Enjoy the beauty of
              eLearning!
            </p>
          </div>
          <div className="row mt-50">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="our-value-box">
                <div className="icon">
                  <PiBrainThin />
                </div>
                <div className="our-value-content">
                  <h3 className="heading-h3">Why Choose Us</h3>
                  <p className="para fw">
                    We make it easier for you by checking out the real-time
                    investing analysis, that too on the trainer's account, who
                    will let you into his thoughts while they are trading. We
                    provide a view of the world inside a trader's head.
                  </p>
                  <Link>Start Now!</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="our-value-box">
                <div className="icon">
                  <IoIosDesktop />
                </div>
                <div className="our-value-content">
                  <h3 className="heading-h3">Our Mission</h3>
                  <p className="para fw">
                    We provide every aspect of trading to give a future to your
                    thoughts rather than just investing and waiting for things
                    to happen; with us, you are ready for your next step based
                    on your last investment outcome.
                  </p>
                  <Link>Start Now!</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="our-value-box">
                <div className="icon">
                  <BsShieldCheck />
                </div>
                <div className="our-value-content">
                  <h3 className="heading-h3">Our Vision</h3>
                  <p className="para fw">
                    In Booming Bulls, we provide you training on the live
                    trading account, and while you are learning, we cover you
                    with all the strategies and help you lookout for the perfect
                    strategy according to your plans.
                  </p>
                  <Link>Start Now!</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* animation design */}
      <div className="anish-section elmb">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fill-opacity="1"
            d="M0,128L120,154.7C240,181,480,235,720,240C960,245,1200,203,1320,181.3L1440,160L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
          ></path>
        </svg>
        <div className="container-section ">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="about-welcome-image" style={{ zIndex: "5" }}>
                <div className="images pe-5">
                  <img src={AshisImg} alt="" />
                </div>
                <div className="shape27">
                  <img src={Shape25} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-7 elmt col-sm-12">
              <div className="about-welcome-content">
                <p
                  className="para fw"
                  style={{ fontSize: "20px", zIndex: "5" }}
                >
                  Having learned from veterans in the field and gained vast,
                  versatile experience in the markets, Anish has adopted a
                  practical, in-depth tested approach in his strategies. Yet, he
                  has designed the course in the simplest manner of
                  undestanding, ensuring accessibility for everyone. Featured in
                  reputable media platforms such as Times of India, Hindustan
                  Times, Economic Times, and more, Anish has remained consistent
                  in delivering excellent stock market education to aspiring
                  traders through his effective teaching methods. He has been
                  honored with the "Millennium Brilliance Award" for the best
                  trainer in the stock market industry, while Booming Bulls
                  Academy, India's leading stock market training institute, has
                  been recognised as the "Most Trusted Stock Market Learning
                  Platform in India" at the Atal Achievement Awards in 2022.
                  Having trained over 25,000 students, Anish continues to share
                  his stock market teachings, enabling passionate individuals to
                  become elite traders.
                </p>
                <h5 className="heading-h5 mt-3" style={{ fontWeight: "800" }}>
                  Anish Singh Thakur
                </h5>
                <p className="para color-red">CEO at Booming Bulls Academy</p>
                <div class="flex-1">
                  <button class="button button-mat btn--8">
                    <span class="psuedo-text">
                      {" "}
                      <span>
                        <FaUser />
                      </span>
                      Enroll Now
                    </span>
                  </button>
                </div>

                <div className="shape25">
                  <img src={Shape24} alt="" />
                </div>
                <div className="shape26">
                  <img src={Shape23} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" className="hide-btn" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fill-opacity="1"
            d="M0,256L120,218.7C240,181,480,107,720,106.7C960,107,1200,181,1320,218.7L1440,256L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>

      {/* our Team */}
      <OurTeam />
      {/* LIMITED SEATS */}
      <div className="about-limited-seat-section">
        <div className="about-limited-seat-container">
          <p className="color-red">LIMITED SEATS LEFT</p>
          <h2 className="heading-h2">
            Register Yourself For Our Upcoming Batch
          </h2>
          <p className="para">
            Check-out Our Upcoming Weekend/Weekday Batch and get yourself
            Enrolled.
          </p>
          <div class="flex-1 mt-4">
            <button class="button button-mat btn--8">
              <span class="psuedo-text">
                {" "}
                <span>
                  <FaUser />
                </span>
                Get Started Now
              </span>
            </button>
          </div>
        </div>
        <div className="about-shape-6">
          <img src={Shape34} alt="" />
        </div>
        <div className="about-shape-7">
          <img src={Shape24} alt="" />
        </div>
        <div className="about-shape-8">
          <img src={Shape23} alt="" />
        </div>
      </div>
      <Contact />

      {/* logo section */}
      <div className="logo-section mt-100">
        <div className="container-section">
          <div className="row">
            <div className="col-md-2 col-sm-6">
              <div className="logo-img">
                <img src={Logo1} alt="" />
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className="logo-img">
                <img src={Logo2} alt="" />
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className="logo-img">
                <img src={Logo3} alt="" />
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className="logo-img">
                <img src={Logo4} alt="" />
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className="logo-img">
                <img src={Logo5} alt="" />
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className="logo-img">
                <img src={Logo6} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
