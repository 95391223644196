import React, { useEffect } from "react";
import { PiNotebookFill } from "react-icons/pi";
import SteveBurns from "../../asstes/Steve-Burns.jpg";
import TradeYourWay from "../../asstes/trade-your-way.jpg";
import SuperTrader from "../../asstes/Super-Trader.jpg";
import TradingHabits from "../../asstes/trading-habits.jpg";
import { Link } from "react-router-dom";
import { IoCheckbox } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineStar } from "react-icons/md";
const RecommendBook = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="container-section mt-100">
        <h2 className="heading-h2 text-center">Books We Recommend</h2>
        <div className="row mt-50">
          <div className="col-lg-3 col-md-6 col-sm-12 my-2">
            <div className="recommend-book-container">
              <img src={SteveBurns} alt="" />
              <h3 className="heading-h3">
                <Link
                  to="https://www.amazon.in/gp/product/1979955808/ref=as_li_tl?ie=UTF8&camp=3638&creative=24630&creativeASIN=1979955808&linkCode=as2&tag=boomingbulls-21&linkId=238e393c572b632b95052e9895cae349"
                  target="_blank"
                >
                  New Trader Rich Trader - Steve Burns
                </Link>
              </h3>
              <div className="experience mt-4">
                <p className="para">Beginner</p>
                <span className="green-icon">
                  <IoCheckbox />
                </span>
              </div>
              <div className="experience">
                <p className="para">Experienced Trader</p>
                <span className="green-icon">
                  <IoCheckbox />
                </span>
              </div>
              <div className="experience">
                <p className="para">Self Help</p>
                <span className="red-icon">
                  <RxCross2 />
                </span>
              </div>
              <div className="star-icons">
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
              </div>
              <button>
                <Link
                  to="https://www.amazon.in/gp/product/1979955808/ref=as_li_tl?ie=UTF8&camp=3638&creative=24630&creativeASIN=1979955808&linkCode=as2&tag=boomingbulls-21&linkId=238e393c572b632b95052e9895cae349"
                  target="_blank"
                >
                  {" "}
                  <PiNotebookFill /> Buy Now
                </Link>
              </button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 my-2">
            <div className="recommend-book-container">
              <img src={TradeYourWay} alt="" />
              <h3 className="heading-h3">
                <Link
                  to="https://www.amazon.in/Trade-Your-Way-Financial-Freedom/dp/007147871X/ref=sr_1_1?crid=3J1G9G5I1ZAHT&keywords=Trade+Your+Way+to+Financial+Freedom&qid=1671691092&s=books&sprefix=trade+your+way+to+financial+freedom%2Cstripbooks%2C274&sr=1-1"
                  target="_blank"
                >
                  Trade Your Way to Financial Freedom
                </Link>
              </h3>
              <div className="experience mt-4">
                <p className="para">Beginner</p>
                <span className="red-icon">
                  <RxCross2 />
                </span>
              </div>
              <div className="experience">
                <p className="para">Experienced Trader</p>
                <span className="green-icon">
                  <IoCheckbox />
                </span>
              </div>
              <div className="experience">
                <p className="para">Self Help</p>
                <span className="red-icon">
                  <RxCross2 />
                </span>
              </div>
              <div className="star-icons">
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
              </div>
              <button>
                <Link
                  to="https://www.amazon.in/Trade-Your-Way-Financial-Freedom/dp/007147871X/ref=sr_1_1?crid=3J1G9G5I1ZAHT&keywords=Trade+Your+Way+to+Financial+Freedom&qid=1671691092&s=books&sprefix=trade+your+way+to+financial+freedom%2Cstripbooks%2C274&sr=1-1"
                  target="_blank"
                >
                  {" "}
                  <PiNotebookFill /> Buy Now
                </Link>
              </button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 my-2">
            <div className="recommend-book-container">
              <img src={SuperTrader} alt="" />
              <h3 className="heading-h3">
                <Link to="" target="_blank">
                  Super Trader - Van Tharp
                </Link>
              </h3>
              <div className="experience mt-4">
                <p className="para">Beginner</p>
                <span className="red-icon">
                  <RxCross2 />
                </span>
              </div>
              <div className="experience">
                <p className="para">Experienced Trader</p>
                <span className="green-icon">
                  <IoCheckbox />
                </span>
              </div>
              <div className="experience">
                <p className="para">Self Help</p>
                <span className="red-icon">
                  <RxCross2 />
                </span>
              </div>
              <div className="star-icons">
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
              </div>
              <button>
                <Link to="" target="_blank">
                  {" "}
                  <PiNotebookFill /> Buy Now
                </Link>
              </button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 my-2">
            <div className="recommend-book-container">
              <img src={TradingHabits} alt="" />
              <h3 className="heading-h3">
                <Link to="" target="_blank">
                  Trading Habits - Steve Burns
                </Link>
              </h3>
              <div className="experience mt-4">
                <p className="para">Beginner</p>
                <span className="green-icon">
                  <IoCheckbox />
                </span>
              </div>
              <div className="experience">
                <p className="para">Experienced Trader</p>
                <span className="green-icon">
                  <IoCheckbox />
                </span>
              </div>
              <div className="experience">
                <p className="para">Self Help</p>
                <span className="red-icon">
                  <RxCross2 />
                </span>
              </div>
              <div className="star-icons">
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
              </div>
              <button>
                <Link to="" target="_blank">
                  {" "}
                  <PiNotebookFill /> Buy Now
                </Link>
              </button>
            </div>
          </div>
          <hr className="my-5" />
          <div className="col-lg-3 col-md-6 col-sm-12 my-2">
            <div className="recommend-book-container">
              <img src={SteveBurns} alt="" />
              <h3 className="heading-h3">
                <Link to="" target="_blank">
                  Trade Mindfully
                </Link>
              </h3>
              <div className="experience mt-4">
                <p className="para">Beginner</p>
                <span className="green-icon">
                  <IoCheckbox />
                </span>
              </div>
              <div className="experience">
                <p className="para">Experienced Trader</p>
                <span className="green-icon">
                  <IoCheckbox />
                </span>
              </div>
              <div className="experience">
                <p className="para">Self Help</p>
                <span className="red-icon">
                  <RxCross2 />
                </span>
              </div>
              <div className="star-icons">
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
                <span>
                  <MdOutlineStar />
                </span>
              </div>
              <button>
                <Link to="" target="_blank">
                  {" "}
                  <PiNotebookFill /> Buy Now
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendBook;
