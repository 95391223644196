export const ContactData = [
  {
    url: "/hybrid-center-ahmedabad",
    title: "Ahmedabad",
    className: "ahmedabad-img",
    Address:
      "303-304, Shivalik 2 Nr. Shivranjani Cross Road, 132 Feet Ring Rd, Jodhpur Village, Ahmedabad, Gujarat 380015",
    Number: "+91 97266 76000",
  },
  {
    url: "/hybrid-center-bengaluru",
    title: "Bengaluru",
    className: "bengaluru-img",
    Address:
      "31/4, Bull Temple Rd, beside Base Education Coaching Center, Gandhi Bazaar, Basavanagudi, Bengaluru, Karnataka 560004    ",
    Number: "+91 99649 90007",
  },
  {
    url: "/hybrid-center-delhi",
    title: "Delhi",
    className: "delhi-img",
    Address:
      "FD-5 Second Floor, Near Gate No. 3, Pitampura Metro Station, Near Vishal Mega Mart, Delhi - 110034",
    Number: "+91 92894 14433",
  },
  {
    url: "/hybrid-center-gurgaon",
    title: "Gurgaon",
    className: "gurgaon-img",
    Address: " K-2/3, 2nd Floor, DLF Phase-2, Gurgaon, Haryana-122008",
    Number: "+91 99111 10212",
  },
  {
    url: "/hybrid-center-hyderabad",
    title: "Hyderabad",
    className: "hyderabad-img",
    deAddress:
      "Flat No.15, Jabbar Apartments, Begumpet, Hyderabad, Telangana 50001    ",
    Number: "+91 74163 33599",
  },
  {
    url: "/hybrid-center-indore",
    title: "Indore",
    className: "indore-img",
    Address:
      "First Floor Plot No 91 Scheme No 114/2 A.B Road, Above Raymond Showroom, Opp Cliffton Corporate, Vijay Nagar, Indore-452010",
    Number: "+91 99099 94948",
  },
  {
    url: "/hybrid-center-jaipur",
    title: "Jaipur",
    className: "jaipur-img",
    Address:
      "K G PLAZA E 95 , SECOND FLOOR, LAL BAHADUR NAGAR, JAY JAWAN MARG , MALVIYA NAGAR, JAIPUR - 302018",
    Number: "+91 99251 78000",
  },
  {
    url: "/hybrid-center-kolkata",
    title: "Kolkata",
    className: "kolkata-img",
    Address:
      "38/5 Anaya Chambers, GN Block , Webel More. Salt Lake Sector V. Kolkata 700091, West Bengal    ",
    Number: "+91 93308 15726",
  },
  {
    url: "/hybrid-center-mumbai",
    title: "Mumbai",
    className: "mumbai-img",
    Address:
      "Office No. 601, 27 MIDC Commercial Premises Co-op Soc. Ltd, 17th Road, M.I.D.C, Kondivita, Andheri East, Mumbai, Maharashtra 400093",
    Number: "+91 82610 99753",
  },
  {
    url: "/hybrid-center-nagpur",
    title: "Nagpur",
    className: "nagpur-img",
    Address:
      "Second Floor of N.I.T Plot No.5, East High Court Road, New Ramdaspeth, Nagpur - 440010",
    Number: "+91 91213 14049",
  },
  {
    url: "/hybrid-center-pune",
    title: "Pune",
    className: "pune-img",
    Address:
      "Maharashtra Technical Education Society Final Plot no 23, off Karve Road, near Sanjeevan Hospital, Erandwane, Pune, Maharashtra 411004    ",
    Number: "+91 72628 55577 ",
  },
  {
    url: "/hybrid-center-surat",
    title: "Surat",
    className: "surat-img",
    Address:
      "Maharashtra Technical Education Society Final Plot no 23, off Karve Road, near Sanjeevan Hospital, Erandwane, Pune, Maharashtra 411004",
    Number: "+91 72628 55577",
  },
];
