import React, { useEffect, useState } from 'react'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { Link } from 'react-router-dom';
import TradingImg from "../../asstes/Trading-plan-blog.png";
import { BlogData } from './BlogData';
const RightBlog = () => {
    const [value, setValue] = useState("Select Category")
    const [selected, setSelected] = useState(false);
    const toggle = () => {
        setSelected(!selected);
    };
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    return (
        <>
            {/* post category */}
            <div className="select-category-section">
                <div className="select-category">
                    <p onClick={toggle}><span>{value}</span> <span className="cat-arrow">
                        {selected ? (
                            <MdOutlineKeyboardArrowUp />
                        ) : (
                            <MdOutlineKeyboardArrowDown />
                        )}
                    </span></p>
                    <ul onClick={toggle} className={selected ? "category-list active" : "category-list"}>
                        <li value={"Beginner Guide to Stock Markets"} onClick={() => setValue("Beginner Guide to Stock Markets")}>Beginner Guide to Stock Markets</li>
                        <li value={"Markets"} onClick={() => setValue("Markets")}>Markets</li>
                        <li value={"Booming Bulls"} onClick={() => setValue("Booming bulls")}>Booming Bulls</li>
                        <li value={"Booming Bulls"} onClick={() => setValue("Booming bulls")}>Booming Bulls</li>
                        <li value={"Booming Bulls"} onClick={() => setValue("Booming bulls")}>Booming Bulls</li>
                        <li value={"Booming Bulls"} onClick={() => setValue("Booming bulls")}>Booming Bulls</li>
                    </ul>
                </div>
            </div>
            {/* search post */}
            <div className="search-blog">
                <label htmlFor="">Search</label>
                <div className='search-btn'>
                    <input type="text" name="" id="" />
                    <button className='enroll-now-btn'>Search</button>
                </div>
            </div>

            {/* Recent posts */}
            <div className="recent-post-section">
                <h3 className="heading-h3">Recent Posts</h3>

                {BlogData.map((item, i) => (
                    <div className="post-content" key={i}>
                        <img src={item.img} alt="" />
                        <div>
                            <p className="para">{item.date}   </p>
                            <p className="fw"><Link>{item.title}: {item.question1}</Link></p>
                        </div>
                    </div>
                ))}
                {/* <div className="post-content">
                    <img src={TradingImg} alt="" />
                    <div>
                        <p className="para">January 31, 2024    </p>
                        <p className="fw"><Link>2024 Trading Strategies: What Every Trader Should Know</Link></p>
                    </div>
                </div> */}

            </div>
        </>
    )
}

export default RightBlog
