import React, { useEffect } from "react";
import { FaPencilRuler } from "react-icons/fa";
import HoverImg from "../../asstes/dots.png";
import { FaBuilding } from "react-icons/fa";
import { TbCalendarUser } from "react-icons/tb";
import { PiStudentDuotone } from "react-icons/pi";
import { MdOutlineCollectionsBookmark } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";
import AshisThakurImg from "../../asstes/ashis-thakur.png";

import Logo1 from "../../asstes/Logo-01.png";
import Logo2 from "../../asstes/Logo-02.png";
import Logo3 from "../../asstes/Logo-03.png";
import Logo4 from "../../asstes/Logo-04.png";
import Logo5 from "../../asstes/Logo-05.png";
import Logo6 from "../../asstes/Logo-06.png";
import CountUp from "react-countup";
import CounterImg from "../../asstes/funfacts-shape2.png";
const WhyJoinUs = () => {
  useEffect(() => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="why-join-us-section">
        <h2 className="heading-h2 text-center">Why Join Us</h2>
        <div className="container-section">
          <div className="row mt-50">
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="why-join-us-box">
                <div className="icon">
                  <FaPencilRuler />
                </div>
                <div>
                  {" "}
                  <h3 className="heading-h3">Revision Classes</h3>
                </div>
                <div className="hover-img">
                  <img src={HoverImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="why-join-us-box">
                <div className="icon">
                  <FaBuilding />
                </div>
                <div>
                  {" "}
                  <h3 className="heading-h3">
                    Proper Infrastucture for Learning
                  </h3>
                </div>
                <div className="hover-img">
                  <img src={HoverImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="why-join-us-box">
                <div className="icon">
                  <TbCalendarUser />
                </div>
                <div>
                  {" "}
                  <h3 className="heading-h3">
                    Personal Attention on Individual Students
                  </h3>
                </div>
                <div className="hover-img">
                  <img src={HoverImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="why-join-us-box">
                <div className="icon">
                  <PiStudentDuotone />
                </div>
                <div>
                  {" "}
                  <h3 className="heading-h3">
                    Expecienced Facility for Doubt Solving
                  </h3>
                </div>
                <div className="hover-img">
                  <img src={HoverImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="why-join-us-box">
                <div className="icon">
                  <MdOutlineCollectionsBookmark />
                </div>
                <div>
                  {" "}
                  <h3 className="heading-h3">Limited Batch of Students</h3>
                </div>
                <div className="hover-img">
                  <img src={HoverImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="why-join-us-box">
                <div className="icon">
                  <FaGraduationCap />
                </div>
                <div>
                  {" "}
                  <h3 className="heading-h3">
                    Learn Under the Mentorship of Anish Singh Thakur
                  </h3>
                </div>
                <div className="hover-img">
                  <img src={HoverImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-section mt-50">
          <div className="row change-direction">
            <div className="col-md-6 col-sm-12">
              <div className="element-conatiner elmt center-box start-box">
                <h2 className="heading-h2">Meet Your Trainer</h2>
                <p className="para">
                  Featured in Hindustan Times, Forbes India, and Yahoo Finance,
                  Anish Singh Thakur has reached a new height where he awares
                  and provides knowledge over the trading myths and strategies
                  on his YouTube Channel. While learning a lot from his channel,
                  he provides more in his classes, not just about trading but
                  also motivates students to live a life that projects the path
                  to their dreams.
                </p>
                <h6 className="heading-h6">
                  <span className="color-tomato">~ Anish Singh Thakur</span>,
                  CEO at Booming Bulls Academy
                </h6>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="element-conatiner ashis-rounded profile-img-card">
                <img src={AshisThakurImg} alt="" />
                <div className="profile-content">
                  <h3>Anish Singh Thakur</h3>
                  <p>CEO</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  experience */}
      <div>
        <div className="learn-experince-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height={100}
            viewBox="0 0 100 100"
            preserveAspectRatio="none" className="top-hight"
          >
            <polygon points="0,0 100,0 0,100" style={{ fill: "#fff" }} />
          </svg>

          <div className="container-section box">
            <div className="learn-experince-content">
              <h2 className="heading-h2">
                Get the offline learning experience
              </h2>{" "}
              <button className="enroll-now-btn"> Download Brochure</button>
            </div>
          </div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height={100}
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            className="bottom-hight"
          >
            <polygon points="0,100 100,0 100,100" style={{ fill: "#fff" }} />
          </svg>
        </div>
      </div>

      {/* logo section */}

      <div className="mt-50">
        <h2 className="heading-h2 text-center ">Featured In</h2>

        <div className="logo-section mt-50">
          <div className="container-section">
            <div className="row">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="logo-img">
                  <img src={Logo1} alt="" />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="logo-img">
                  <img src={Logo2} alt="" />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="logo-img">
                  <img src={Logo3} alt="" />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="logo-img">
                  <img src={Logo4} alt="" />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="logo-img">
                  <img src={Logo5} alt="" />
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <div className="logo-img">
                  <img src={Logo6} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* counter up */}
      <div className="container-section mt-50">
        <h2 className="heading-h2 text-center">Our Success Ratio</h2>
        <div className="counter-up-section">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="img">
                <img src={CounterImg} alt="" />
                <h2>
                  <CountUp delay={2} end={4} />.<CountUp delay={2} end={6} />
                </h2>
                <p>GOOGLE RATING</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="img">
                <img src={CounterImg} alt="" />
                <h2>
                  <CountUp delay={3} end={2} />M
                </h2>
                <p>YOUTUBE SUBSCRIBERS</p>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="img">
                <img src={CounterImg} alt="" />
                <h2>
                  <CountUp delay={2} end={1000} />+
                </h2>
                <p>SESSIONS FINISHED</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="img">
                <img src={CounterImg} alt="" />
                <h2>
                  <CountUp delay={3} end={27000} />+
                </h2>
                <p>STUDENTS MENTORED</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyJoinUs;
