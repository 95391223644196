import React, { useState, useEffect } from "react";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../asstes/logo.png";
import { IoIosArrowDown } from "react-icons/io";
import { ContactData } from "../utils/ContactData";
import { RxCross2 } from "react-icons/rx";
import { FaBars } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { HiMinusSmall } from "react-icons/hi2";
import { VscMenu } from "react-icons/vsc";
import { NaveData } from "./NavData";
const Navbar = () => {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClickOpen = (i) => {
    if (open === i) {
      return setOpen(true);
    }
    setOpen(i);

  }

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);


  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  let x = ["navbar"];
  if (scrolled) {
    x.push("scrolled");
  }

  //********** */ mobile menu **********
  const handleScrollMobile = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollMobile);
  });

  let xm = ["navbar-mobile"];
  if (scrolled) {
    xm.push("scrolled");
  }



  return (
    <>
      {/* */}
      <header className={x.join(" ")}>
        <div className="logo" onClick={() => navigate("/")}>
          <img src={Logo} alt="" />
        </div>
        <nav className="navigation">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="">Home</Link>

            </li>
            <li className="">
              <Link to="/about-us">
                About Us{" "}
                <span>
                  <IoIosArrowDown />
                </span>
              </Link>
              <ul className="dropdowns">
                <li>
                  <Link to={"/about-us"}>About Booming Bulls</Link>
                </li>
                <li>
                  <Link to={"/testimonials"}>Testimonials</Link>
                </li>
                <li>
                  <Link to={"/faq"}>FAQ</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/courses/elite-traders-live-mentorship-program-4-0">
                Online Courses
              </Link>
            </li>
            <li className="">
              <Link to="/about-us">
                Offline Courses{" "}
                <span>
                  <IoIosArrowDown />
                </span>
              </Link>
              <ul className="dropdowns">
                {ContactData.map((data, i) => (
                  <li key={i} onClick={() => toggle(i)}>
                    <Link
                      to={data.url}
                      className={selected === i ? "active" : ""}
                    >
                      {data.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link to="/resources">Resources</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>

        </nav>

      </header>

      {/* ============== mobile menu ================*/}

      <header className={xm.join(" ")}>
        <div className="logo" onClick={() => navigate("/")}>
          <img src={Logo} alt="" />
        </div>
        <nav className="mobile-navigation">

          <ul className={click ? "mobile-nav-menu active" : "mobile-nav-menu"}>
            {NaveData.map((item, i) => (
              <li className="mobile-nav-item" key={i} onClick={() => handleClickOpen(i)}>
                <Link to={item.url} >{item.name}
                  {item.submenu && (
                    <span > {open === i ?
                      <HiMinusSmall /> : <GoPlus />
                    }
                    </span>)}

                </Link>
                {item.submenu && (
                  <>
                    <ul className={open === i ? "mobile-dropdown active" : "mobile-dropdown"}>
                      {item.insideName.map((sub) => (
                        <li onClick={handleClick}>
                          <Link to={sub.link}>{sub.names}</Link>
                        </li>

                      ))}
                    </ul>
                  </>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div>
          <span className="bar-section" onClick={handleClick}>
            {click ? <RxCross2 /> : <VscMenu />}
          </span>
        </div>
      </header>
    </>


  );
};
export default Navbar;


