import React, { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { LuPhoneCall } from "react-icons/lu";
import { IoMdTime } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const Contact = () => {
  const [input, setInput] = useState("");

  const handleChange = (e) => {
    // const result = e.target.value.replace(/[^a-z]/gi, "");
    setInput(e.target.value);
  };
  return (
    <>
      <div className="container-section mt-100">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="contact-info center-box">
              <p className="color-red">CONTACT DETAILS</p>
              <h2 className="heading-h2">Get in Touch</h2>

              <p className="para">
                Have any queries? or want to know more? Contact us and we will
                be happy to help.
              </p>
              <ul className="mt-50">
                <li>
                  <div className="icon">
                    <FaMapMarkerAlt />
                  </div>
                  <h3 className="heading-h3">Our Address</h3>
                  <p className="para fw">
                    A-36, 3rd Floor, Sector 4, Noida 201301
                  </p>{" "}
                </li>
                <li>
                  <div className="icon">
                    <MdOutlineMailOutline />
                  </div>
                  <h3 className="heading-h3">E-mail</h3>
                  <p className="para fw">
                    General Enquiry: <br />
                    <a
                      href="mailto:info@boomingbulls.com"
                      className="color-red"
                    >
                      info@boomingbulls.com
                    </a>
                  </p>
                  <p className="para fw">
                    Business Enquiry: <br />
                    <a
                      href="mailto:deals@boomingbulls.com"
                      className="color-red"
                    >
                      deals@boomingbulls.com
                    </a>
                  </p>{" "}
                </li>
                <li>
                  <div className="icon">
                    <LuPhoneCall />
                  </div>
                  <h3 className="heading-h3">Contact</h3>
                  <p className="para fw">
                    Mobile:{" "}
                    <a href="tel:+ 91 81302-45100" className="color-red">
                      + 91 81302-45100
                    </a>
                  </p>
                </li>
                <li>
                  <div className="icon">
                    <IoMdTime />
                  </div>
                  <h3 className="heading-h3">Hours of Operation</h3>
                  <p className="para fw">
                    Everyday : 10:00 AM - 07:00 PM IST
                  </p>{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-container">
              <h2 className="heading-h2">Ready to Get Started?</h2>
              <p className="para">
                Fill Up The Form to get a Call from our Academic Counselor & to
                Download our Brochure*
              </p>
              <div className="form-input">
                <div className="input name-field">
                  <div className="name">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      name=""
                      value={input}
                      onChange={handleChange}
                      id=""
                    />
                    <span>Fist name</span>
                  </div>
                  <div className="name mt-4">
                    <label htmlFor="" className="mb-4"></label>
                    <input
                      type="text"
                      name=""
                      value={input}
                      onChange={handleChange}
                      id=""
                    />
                    <span>Last name</span>
                  </div>
                </div>
                <div className="input">
                  <label htmlFor="">Email</label>
                  <input type="email" name="" id="" />
                </div>
                <div className="input">
                  <label htmlFor="">Phone</label>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    country={"in"}
                    value={input}
                    onChange={() => setInput(input)}
                  />
                </div>
                <div className="input">
                  <label htmlFor="">City</label>
                  <input type="text" name="" id="" />
                </div>
                <div className="input">
                  <label htmlFor="">
                    How much capital you are planning to trade with? *
                  </label>
                  <select>
                    <option value="">0 to 50,000</option>
                    <option value="">50,000 to 2,00,000</option>
                    <option value="">2,00,000 to 5,00,000</option>
                    <option value="">Above 5,00,000</option>
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="">Which batch do you want to join ? *</label>
                  <select>
                    <option value="">February 2024</option>
                    <option value="">March 2024</option>
                    <option value="">April 2024</option>
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="">Your Query *</label>
                  <input type="text" name="" id="" />
                </div>
                <div className="input checkbox">
                  <input type="checkbox" name="" id="check" />
                  <label htmlFor="check">
                    Accept terms and privacy policy. *
                  </label>
                </div>
                <button className="btn">Send Message</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
