import { BsJournalText } from "react-icons/bs";
import { HiLockClosed } from "react-icons/hi";

export const LessonData = [
  {
    url: "/courses/lesson",
    slug: "/basic-1",
    title: "Basic Of Stock Market 1",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    url: "/courses/lesson",
    slug: "/basic-2",
    title: "Fandamental Analysis Basic 2",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    url: "/courses/lesson",
    slug: "/basic-3",
    title: "Fandamental Analysis Basic 3",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    url: "/courses/lesson",
    title: "Fandamental Analysis Basic 4",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    url: "/courses/lesson",
    title: "Fandamental Analysis Basic 5",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    url: "/courses/lesson",
    title: "Fandamental Analysis Basic 6",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    url: "/courses/lesson",
    title: "Fandamental Analysis Basic 7",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    url: "/courses/lesson",
    title: "Fandamental Analysis Basic 8",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    url: "/courses/lesson",
    title: "Fandamental Analysis Basic 9",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    url: "/courses/lesson",
    title: "Fandamental Analysis Basic 10",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    url: "/courses/lesson",
    title: "Fandamental Analysis Basic 11",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
];

export const LessonDataOptions = [
  {
    title: "Options Trading Deep Drive 1",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    title: "Options Trading Deep Drive 2",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    title: "Options Trading Deep Drive 3",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    title: "Options Trading Deep Drive 4",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
  {
    title: "Options Trading Deep Drive 5",
    textIcon: <BsJournalText />,
    lockIcon: <HiLockClosed />,
  },
];
