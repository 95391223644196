import React, { useState } from "react";
import "./faq.css";

import { BiSolidFlagCheckered } from "react-icons/bi";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { BiSolidBadgeDollar } from "react-icons/bi";

import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
const data = [
  {
    id: "1",
    tabTitle: "General Guide",
    icons: <BiSolidFlagCheckered />,
    datas: [
      {
        id: 1,
        question: "How long will the training go on for ?",
        answer:
          "You will have 18 regular classes for Weekday Batch and 12 regular classes for Weekend Batch. Both batches will have same number of training hours. After you complete your regular classes, you will have post market analysis and also other special sessions occasionally.",
      },
      {
        id: 2,
        question: "How can I contact the company once I have paid the fees ?",
        answer:
          "Our Support Team's number will be shared with you once you're enrolled.",
      },
      {
        id: 3,
        question: "What’s your Membership Fees ?",
        answer: "The online training fee is only “₹29,972”",
      },
      {
        id: 4,
        question: "Can I attend a demo class for free ?  ",
        answer:
          "Through our new model you can attend our regular 3 online classes of the weekday batch or 2 online classes of the weekend batch by paying just Rs3,000. Later you can pay the remaining amount. In case, if you wish to discontinue, the Rs3,000 will be refunded without any questions asked.",
      },
      {
        id: 5,
        question: "Is the Training Beginner Friendly ?  ",
        answer:
          "Yes, The mentorship program is design such a way that it takes you from very basics to advanced levels in a very simple and clear language that even a school child can learn with no prior financial knowledge, so that you won't feel left out.",
      },
      {
        id: 6,
        question: "Is the Trainer qualified enough ? ",
        answer:
          "The trainer has rich experience in stock market trading. Having learnt from veterans in the field of stockmarket, he has adopted a practical approach with his own solid strategies using which anyone can make money consistently from trading. You can find out more about him on Instagram “@anishsinghthakur” and on his website “www.anishsinghthakur.com”, where you can see reviews from his pasts students and his consistent winning profitable trades.",
      },
      {
        id: 7,
        question: "Is trading risky ? ",
        answer:
          "Yes, it is risky for those who trade without any proper training and knowledge",
      },
    ],
  },
  {
    id: "2",
    tabTitle: "Course Guide",
    icons: <BiSolidBadgeDollar />,

    datas: [
      {
        id: 1,
        question:
          "In which language does the trainer conduct live classes ?        ",
        answer:
          "Mostly Hindi language with terminology in English. You can watch our YouTube uploads to have a better understanding.",
      },
      {
        id: 2,
        question: "How to attend the online classes ?   ",
        answer:
          "Online classes are conducted through Zoom application in which trainer's laptop screen will be visible to you for charts. iPad screen will be visible to you as a white board & trainer will explain everything to you live. It is as good as the offline classes & we are talking from experience.",
      },
      {
        id: 3,
        question: "Do you teach Price Action ?",
        answer:
          "Yes, we have a few strategies that are purely based on Price Action.",
      },
      {
        id: 4,
        question:
          "Will you provide recording for the first three classes if I miss any ?",
        answer:
          "There will be multiple revision classes for every class so you can attend revision class for the same topic on the same day, the next day, and also again in the same week. You can also attend revision classes from other batches upto 3 months after you finish your own classes.",
      },
      {
        id: 5,
        question:
          "What makes your 'Booming Bulls Professional Trader Premium Group' on facebook so special ?",
        answer:
          "In this group, we share analysis of stocks, indices & forex which will give you a heads up on what you can trade the next day. All these analyses are approved by Anish Singh Thakur. This group is strictly not for any kind of trading signals & we do not entertain any such practices.",
      },
      {
        id: 6,
        question: "How will the trainer clear my doubts during a live class ? ",
        answer:
          "We do not allow students to interrupt class with doubts while the trainer is teaching. Students are required to note down all the doubts & send it to our support team. All the answers are approved by Anish Singh Thakur himself. You will not face difficulty in live class as the trainer has enough experience to explain everything in a very simple manner easy to understand. We also conduct FAQs session regularly.",
      },
    ],
  },
  {
    id: "3",
    tabTitle: "Experienced Traders",
    icons: <LiaShoppingBagSolid />,

    datas: [
      {
        id: 1,
        question:
          "I am a trader and I only need strategies. Will it be useful for me ? ",
        answer:
          "Yes, our Trading Plan is useful for those who are already trading and want to restart trading to recover losses and become consistently profitable.",
      },
      {
        id: 2,
        question: "What's the accuracy of your strategies ?        ",
        answer: "Our strategies are 75-80% accurate.",
      },
      {
        id: 3,
        question:
          "Will your provide tips for trading or any advice for investment purpose ?        ",
        answer:
          "No. We are not involved in such practices and we advice that you never expect it from us at any point of your course duration or afterwards. Our only goal here is to provide training so that you do not have to depend on others for signals and you become an independent successful trader.",
      },
    ],
  },
];

export default function FAQ() {
  const [visibleTab, setVisibleTab] = useState(data[0].id);

  //   faq start
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  // faq end

  const listTitles = data.map((item) => (
    <li
      onClick={() => setVisibleTab(item.id)}
      className={
        visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"
      }
    >
      <span>{item.icons}</span>
      {item.tabTitle}
    </li>
  ));

  const listContent = data.map((item) => (
    <p style={visibleTab === item.id ? {} : { display: "none" }}>
      {item.datas.map((item, i) => (
        <div className="item">
          <div className="title" onClick={() => toggle(i)}>
            <span className={selected === i ? "faq-icon active" : "faq-icon"}>
              {selected === i ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </span>
            <h2>{item.question}</h2>
          </div>
          <div className={selected === i ? "content show" : "content"}>
            {item.answer}
          </div>
        </div>
      ))}
    </p>
  ));
  {
    /* <MdOutlineKeyboardArrowUp /> */
  }
  return (
    <>
      <div className="container-section mt-100">
        <div className="faq-section">
          <div className="tabs">
            <ul className="tabs-titles"> {listTitles}</ul>
            <div className="tab-content mt-50">{listContent}</div>
          </div>
        </div>
      </div>
    </>
  );
}
