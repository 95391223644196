import React from "react";
import { FaUser } from "react-icons/fa";
import Shape30 from "../../asstes/shape25.png";
import Shape24 from "../../asstes/shape24.png";

import Shape31 from "../../asstes/shape26.png";
import Shape32 from "../../asstes/shape27.png";
const Newsletter = () => {
  return (
    <>
      <div className="news-latter-section mt-100">
        <div className="news-letter-container">
          <p className="color-red">GO AT YOUR OWN PACE</p>
          <h2 className="heading-h2">Subscribe to Our Newsletter</h2>
          <div>
            <p className="para">
              For information on regular updates, batch launches, important
              announcements, and specials classes, please subscribe to our
              newsletter
            </p>
          </div>
          <div className="input-box">
            <input
              type="text"
              placeholder="Enter Your Email Address"
              name=""
              id=""
            />
            <div class="flex-1">
              <button class="button button-mat btn--8">
                <span class="psuedo-text">
                  {" "}
                  <span>
                    <FaUser />
                  </span>
                  Subscribe Now
                </span>
              </button>
            </div>
          </div>

          {/* shape  */}
          <div className="shape32">
            <img src={Shape32} alt="" />
          </div>
          <div className="shape28">
            <img src={Shape24} alt="" />
          </div>
          <div className="shape30">
            <img src={Shape30} alt="" />
          </div>
          <div className="shape31">
            <img src={Shape31} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
