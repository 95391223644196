import React, { useEffect } from 'react'
import MetaData from '../../layout/MetaData'
import { Link } from 'react-router-dom'
import AhmedabadImg from "../../asstes/Ahmedabad-Hybrid-Center.png";
import BengaluruImg from "../../asstes/Bengaluru.png";
import DelhiImg from "../../asstes/Delhi-Hybrid-Center.png";
import GurgaonImg from "../../asstes/Gurgaon-new.png";
import HydrabadImg from "../../asstes/HYD.png";
import IndoreImg from "../../asstes/Indore.png";
import JaipurImg from "../../asstes/Jaipur-Address.png";
import KolkataImg from "../../asstes/Kolkata-Hybrid.png";
import MumbaiImg from "../../asstes/mumbai.jpeg";
import NagpurImg from "../../asstes/Nagpur.png";
import PuneImg from "../../asstes/Pune.png";
import SuratImg from "../../asstes/Surat.png";

import CrossShape from "../../asstes/cross-shape.svg";
import Contact from '../home/Contact';
const ContactPage = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    return (
        <>
            <MetaData title="Booming Bulls" />
            <MetaData title="Contact Us Booming Bulls" />
            <div className="about-banner-section">
                <div className="banner-content">
                    <h1 className="heading-h2">Contact Us</h1>
                    <p className="para">
                        <Link className="color-white" to="/">
                            Home
                        </Link>{" "}
                        / <b>Contact Us</b>
                    </p>
                    <div className="cross-shape">
                        <img src={CrossShape} alt="" />
                    </div>
                </div>
            </div>
            <Contact />

            {/*  */}
            {/* Our Hybrid Centers */}
            <div className="container-section mt-100">
                <h2 className="heading-h2 text-center">Our Hybrid Centers</h2>
                <div className="our-hybrid-section mt-50">
                    <div className="hybrid-box">
                        <img src={AhmedabadImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={BengaluruImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={DelhiImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={GurgaonImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={HydrabadImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={IndoreImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={JaipurImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={KolkataImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={MumbaiImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={NagpurImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={PuneImg} alt="" />
                        <button>Know More</button>
                    </div>
                    <div className="hybrid-box">
                        <img src={SuratImg} alt="" />
                        <button>Know More</button>
                    </div>
                </div>
            </div>


            {/* map */}
            <div className="map-section mt-100">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448193.9510282254!2d76.76356975916202!3d28.644287353977727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1707037438653!5m2!1sen!2sin" width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

            </div>
        </>
    )
}

export default ContactPage
