import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FaArrowsAltV } from "react-icons/fa";
import "./lesson.css";
import { LessonData } from "./LessonData";
import { LessonDataOptions } from "./LessonData";
import Text from "./Text";

import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
const Lesson = () => {
  const [selected, setSelected] = useState(null);
  // const [lecture, setLecture] = useState({});
  // console.log("lec", lecture);

  const navigate = useNavigate()
  const myArray = LessonData;
  const [link, setLink] = useState()
  console.log("ii", link);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  // scroll

  //
  let counter = 0;
  for (let i = 0; i < LessonData.length; i++) {
    if (LessonData[i].title) counter++;
  }

  // lecture index
  const [currentName, setCurrentName] = useState(myArray[0].title);
  const [nextButton, setNextButton] = useState(myArray[1].title);
  const [prevButton, setPretButton] = useState(myArray[0].title);
  const [newprevButton, setNewPretButton] = useState(0);
  console.log("object 0", newprevButton);
  console.log("select", selected);

  // next lecture
  const getNextItem = () => {
    const currentIndex = myArray.findIndex(
      (item) => item.title === currentName
    );
    const nextIndex = (currentIndex + 1) % myArray.length;
    setCurrentName(myArray[nextIndex].title);
    setLink(myArray[nextIndex].url + myArray[nextIndex].slug)
    getNextButton();
    getPrevButton();
    toggle(nextIndex);

  };


  //  next bttuon +++
  const getNextButton = () => {
    const currentIndex = myArray.findIndex((item) => item.title === nextButton);
    const nextIndex = (currentIndex + 1) % myArray.length;
    setNextButton(myArray[nextIndex].title);
  };
  //  prev bttuon +++
  const getPrevButton = () => {
    const currentIndex = myArray.findIndex((item) => item.title === prevButton);
    const nextIndex = (currentIndex + 1) % myArray.length;
    setPretButton(myArray[nextIndex].title);
    setNewPretButton(myArray[nextIndex - 1].title);

  };

  // prev lecture
  const getPreviousItem = () => {
    const currentIndex = myArray.findIndex(
      (item) => item.title === currentName
    );
    const previousIndex = (currentIndex - 1 + myArray.length) % myArray.length;
    setCurrentName(myArray[previousIndex].title);
    // setLink(myArray[previousIndex].url + myArray[previousIndex].slug)
    getNextButtons();
    getPrevButtons();
    toggle(previousIndex);

    // console.log("ii", previousIndex);
  };
  //  next bttuon ---
  const getNextButtons = () => {
    const currentIndex = myArray.findIndex((item) => item.title === nextButton);
    const previousIndex = (currentIndex - 1 + myArray.length) % myArray.length;
    setNextButton(myArray[previousIndex].title);
  };
  //  prev bttuon ---
  const getPrevButtons = () => {
    const currentIndex = myArray.findIndex((item) => item.title === prevButton);
    const previousIndex = (currentIndex - 1 + myArray.length) % myArray.length;
    setPretButton(myArray[previousIndex].title);
    setNewPretButton(myArray[previousIndex - 1]?.title);

  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="lesson-top-section">
        <div className="left-section">
          <div className="search-box">
            <input type="text" placeholder="Search Item" name="" id="" />
            <span>
              <FaSearch />
            </span>
          </div>
          <h3 className="heading-h3 color-white">
            Elite Traders Live Mentorship Program 4.0
          </h3>
        </div>
        <div className="right-section">
          <button className="enroll-now-btn">
            <Link>Back To Course</Link>
          </button>
          <span className="icon">
            <FaArrowsAltV />
          </span>
        </div>
      </div>

      {/* lesson list */}
      <div className="lesson-list-section">
        <div className="lesson-list left">
          <div className="online-lecture-list-container lesson-content">
            <h5 className="heading-h5 pe-3 pt-4 pb-2">{counter}</h5>
            <ul className="lecture-list">
              {LessonData.map((item, i) => (
                <li
                  key={i}
                  onClick={() => toggle(i)}
                  className={selected === i ? "bg-active" : ""}
                >
                  <Link >
                    <div className="title">
                      {item.textIcon}
                      <span>{item.title}</span>
                    </div>
                    <div className="lock">{item.lockIcon}</div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="lesson-list right mt-50">
          <div className="container-section">
            <p>Current Item: {currentName}</p>

            <div className="pre-next-section">


              {newprevButton === 0 || selected === 0 ? (
                " "
              ) : (
                <div className="button-box prev" >
                  <b>Prev</b>
                  <button onClick={getPreviousItem}>
                    <span>
                      <IoIosArrowBack />
                    </span>
                    {newprevButton}
                  </button>
                </div>
              )}

              {selected === counter - 1 ? (
                " "
              ) : (
                <div className="button-box next" >
                  <b className="next">Next</b>
                  <button onClick={getNextItem}>
                    {nextButton}
                    <span>
                      <IoIosArrowForward />
                    </span>{" "}
                  </button>
                </div>
              )}
            </div>
            {/* <Text /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Lesson;
