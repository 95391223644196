import React from "react";
import "./footer.css";
import { IoCallSharp } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { BiSolidCheckbox } from "react-icons/bi";
import { PiMapPinThin } from "react-icons/pi";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import FooterLogo from "../asstes/logo2.png";
const Footer = () => {
  return (
    <>
      <footer className="">
        <div className="container-section">
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <div className="footer-content">
                <h2 className="heading-h2">Booming Bulls Academy</h2>
                <div className="social-media-icon">
                  <span>
                    <Link to="" target="_blank">
                      <FaFacebookF />
                    </Link>
                  </span>
                  <span>
                    <Link to="" target="_blank">
                      <FaPinterestP />
                    </Link>
                  </span>
                  <span>
                    <Link to="" target="_blank">
                      <FaTwitter />
                    </Link>
                  </span>
                  <span>
                    <Link to="" target="_blank">
                      <IoLogoInstagram />
                    </Link>
                  </span>
                </div>
                <div className="footer-logo mt-150">
                  <img src={FooterLogo} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="footer-content">
                <h2 className="heading-h5">Explore</h2>
                <ul className="footer-ul">
                  <li>
                    <i>
                      <BiSolidCheckbox />
                    </i>{" "}
                    About{" "}
                  </li>
                  <li>
                    <i>
                      <BiSolidCheckbox />
                    </i>{" "}
                    Blog{" "}
                  </li>
                  <li>
                    <i>
                      <BiSolidCheckbox />
                    </i>{" "}
                    Important Links{" "}
                  </li>
                  <li>
                    <i>
                      <BiSolidCheckbox />
                    </i>{" "}
                    Courses{" "}
                  </li>
                  <li>
                    <i>
                      <BiSolidCheckbox />
                    </i>{" "}
                    Careers{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="footer-content">
                <h2 className="heading-h5">Resources</h2>
                <ul className="footer-ul">
                  <li>
                    <i>
                      <BiSolidCheckbox />
                    </i>{" "}
                    Testimonials
                  </li>
                  <li>
                    <i>
                      <BiSolidCheckbox />
                    </i>{" "}
                    Upcoming Events
                  </li>
                  <li>
                    <i>
                      <BiSolidCheckbox />
                    </i>{" "}
                    Terms & Conditions
                  </li>
                  <li>
                    <i>
                      <BiSolidCheckbox />
                    </i>{" "}
                    Privacy Policy
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="footer-content">
                <h2 className="heading-h5">Address</h2>
                <ul className="footer-ul">
                  <li>
                    <span>
                      <PiMapPinThin />
                    </span>
                    Office No.-301, H-74, Sec-63, Noida 201301
                  </li>
                  <li>
                    <span>
                      <FiPhoneCall />
                    </span>
                    + 91 81302-45100
                  </li>
                  <li>
                    <span>
                      <MdOutlineMailOutline />
                    </span>
                    info@boomingbulls.com
                  </li>
                </ul>

                <div className="social-media-icon ">
                  <span>
                    <Link to="" target="_blank">
                      <FaFacebookF />
                    </Link>
                  </span>
                  <span>
                    <Link to="" target="_blank">
                      <FaPinterestP />
                    </Link>
                  </span>
                  <span>
                    <Link to="" target="_blank">
                      <FaTwitter />
                    </Link>
                  </span>
                  <span>
                    <Link to="" target="_blank">
                      <IoLogoInstagram />
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright mt-100">
            <div>
              <p className="para">
                © 2019-2024 Booming Bulls. All rights reserved.
              </p>
            </div>
            <div>
              <Link>Terms & Conditions</Link>
              <span className="mx-2"> |</span>
              <Link> Privacy Policy</Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
