import React from "react";
import { FaUser } from "react-icons/fa";
import AboutImg from "../../asstes/Web-Element.png";
import Shape16 from "../../asstes/shape16.png";
import Shape17 from "../../asstes/shape17.png";
import Shape18 from "../../asstes/shape18.png";
import Shape19 from "../../asstes/shape19.png";
import Shape20 from "../../asstes/shape20.png";
import Shape21 from "../../asstes/shape21.png";
import Shape22 from "../../asstes/shape22.png";
import Shape23 from "../../asstes/shape23.png";
import Shape24 from "../../asstes/shape24.png";
import Shape25 from "../../asstes/shape2-1.png";
import WhyChoose from "../../asstes/why-choose-up.jpg";
import { PiStudentFill } from "react-icons/pi";
import { GiBackwardTime } from "react-icons/gi";
import { TbChartRadar } from "react-icons/tb";
import CounterImg from "../../asstes/funfacts-shape2.png";
import BenefitImg from "../../asstes/IMG-8657-min-scaled-1.jpg";
import AshisImg from "../../asstes/ashis.jpg";
import EventImg from "../../asstes/event-img.jpg";
import CountUp from "react-countup";

import InformationImg from "../../asstes/information-img-min.jpg";
import { IoMdPlay } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";

import { FiCheckSquare } from "react-icons/fi";
import { PiUserRectangleBold } from "react-icons/pi";
import { TiBusinessCard } from "react-icons/ti";
import { FaBuildingCircleCheck } from "react-icons/fa6";
import { FaFileDownload } from "react-icons/fa";
const About = () => {
  return (
    <>
      {/* Welcome to Booming Bulls Academy */}
      <div className="container-section mt-100">
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <div className="about-welcome-content">
              <p className="para color-red">ABOUT US</p>
              <h2 className="heading-h2">Welcome to Booming Bulls Academy</h2>
              <p className="para">
                Booming Bulls Academy is the Best Stock Market Institute that
                provides you with Best Online Stock Market course in India. It
                is an initiative for the young generation who are keen on making
                more, willing to step forward, and want to be free from
                financial situations.
              </p>
              <p className="para fw">
                Generally, trading is considered as a business that is not
                suitable for everyone, but this is just a hoax that we clear by
                our program focuses on making you learn long-tested strategies
                that have proven to be profitable.
              </p>

              <div class="flex-1">
                <button class="button button-mat btn--8">
                  <span class="psuedo-text">
                    {" "}
                    <span>
                      <FaUser />
                    </span>
                    Enroll Now
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-sm-12">
            <div className="about-welcome-image">
              <div className="images">
                <img src={AboutImg} alt="" />
              </div>
              <div className="shape16">
                <img src={Shape16} alt="" />
              </div>
              <div className="shape17">
                <img src={Shape17} alt="" />
              </div>
              <div className="shape18">
                <img src={Shape18} alt="" />
              </div>
              <div className="shape19">
                <img src={Shape19} alt="" />
              </div>
              <div className="shape20">
                <img src={Shape20} alt="" />
              </div>
              <div className="shape21">
                <img src={Shape21} alt="" />
              </div>
              <div className="shape22">
                <img src={Shape22} alt="" />
              </div>
              <div className="shape23">
                <img src={Shape23} alt="" />
              </div>
              <div className="shape24">
                <img src={Shape24} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* WHY CHOOSE US */}
      <div className="container-section mt-100">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="about-welcome-image">
              <div className="images">
                <img src={WhyChoose} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="about-welcome-content">
              <p className="para color-red">WHY CHOOSE US</p>
              <h2 className="heading-h2">We Simplify Trading</h2>
              <p className="para">
                Booming Bulls Academy over the period of time has evolved & can
                help anyone with no experience & no prior knowledge about the
                markets to become a consistent trader capable of transforming
                your financial growth.
              </p>
              <div className="why-choose-ul">
                <li>
                  <span>
                    {" "}
                    <i>
                      <PiStudentFill />
                    </i>
                    Revision Classes
                  </span>
                </li>
                <li>
                  <span>
                    <i>
                      <GiBackwardTime />
                    </i>{" "}
                    3 Months Support
                  </span>
                </li>
                <li>
                  <span>
                    {" "}
                    <i>
                      <TbChartRadar />
                    </i>
                    Trading System
                  </span>
                </li>
              </div>
              <div class="flex-1 mt-3">
                <button class="button button-mat btn--8">
                  <span class="psuedo-text">
                    {" "}
                    <span>
                      <FaUser />
                    </span>
                    Enroll Now
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* counter up */}
      <div className="container-section mt-100">
        <div className="counter-up-section">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="img">
                <img src={CounterImg} alt="" />
                <h2>
                  <CountUp delay={2} end={2} />M
                </h2>
                <p>YOUTUBE SUBSCRIBERS</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="img">
                <img src={CounterImg} alt="" />
                <h2>
                  <CountUp delay={2} end={25000} />+
                </h2>
                <p>STUDENTS MENTORED</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="img">
                <img src={CounterImg} alt="" />
                <h2>
                  <CountUp delay={2} end={1000} />+
                </h2>
                <p>SESSIONS FINISHED</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="img lst">
                <img src={CounterImg} alt="" />
                <h2>
                  <CountUp delay={2} end={4} />.<CountUp delay={2} end={6} />
                </h2>
                <p>GOOGLE RATING</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* benefit */}
      <div className="container-section mt-100">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="about-welcome-content benefit">
              <p className="para color-red">
                BENEFITS OF ENROLLING IN OUR COURSE
              </p>
              <h2 className="heading-h2">
                JOIN THE MOST SIMPLIFIED TRADING COURSE FROM ANISH SINGH THAKUR
              </h2>
              <p className="para">
                Booming Bulls trading course can provide you to learn about the
                stock market from the basics, where you get to learn how does a
                market operates to how can you earn simply by putting all the
                logical thinking into the action. Our simplified strategies will
                precisely guide you on understanding earning and achieving
                financial independence efficiently and effectively.
              </p>
              <ul className="benefit-course-ul">
                <li>
                  <FaCircleCheck />
                  <span>Revision Classes</span>
                </li>
                <li>
                  <FaCircleCheck />
                  <span>5 Star Trading Setup</span>
                </li>
                <li>
                  <FaCircleCheck />
                  <span>Booming Bulls Facebook Stock Selection Group</span>
                </li>
                <li>
                  <FaCircleCheck />
                  <span>Trading System Development</span>
                </li>
                <li>
                  <FaCircleCheck />
                  <span>Trading Psychology</span>
                </li>
                <li>
                  <FaCircleCheck />
                  <span>Ultimate Trader's Checklist</span>
                </li>
              </ul>

              <div class="flex-1 mt-3">
                <button class="button button-mat btn--8">
                  <span class="psuedo-text">
                    {" "}
                    <span>
                      <FaUser />
                    </span>
                    Enroll Now
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="about-welcome-image ">
              <div className="benefit-img">
                <img src={BenefitImg} alt="" />
                <a
                  href="https://www.youtube.com/watch?v=vP_wawbgYJE"
                  class="video-btn tutorial__pulsar"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  <span>
                    <IoMdPlay />
                  </span>
                </a>
                <span className="title">
                  India's No. 1 Stock Market Trainer
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* animation design */}
      <div className="anish-section ">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fill-opacity="1"
            d="M0,128L120,154.7C240,181,480,235,720,240C960,245,1200,203,1320,181.3L1440,160L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
          ></path>
        </svg>
        <div className="container-section ">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="about-welcome-image" style={{ zIndex: "5" }}>
                <div className="images pe-5">
                  <img src={AshisImg} alt="" />
                </div>
                <div className="shape27">
                  <img src={Shape25} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="about-welcome-content elmt elmb">
                <p
                  className="para fw"
                  style={{ fontSize: "20px", zIndex: "5" }}
                >
                  Having learned from veterans in the field and gained vast,
                  versatile experience in the markets, Anish has adopted a
                  practical, in-depth tested approach in his strategies. Yet, he
                  has designed the course in the simplest manner of
                  undestanding, ensuring accessibility for everyone. Featured in
                  reputable media platforms such as Times of India, Hindustan
                  Times, Economic Times, and more, Anish has remained consistent
                  in delivering excellent stock market education to aspiring
                  traders through his effective teaching methods. He has been
                  honored with the "Millennium Brilliance Award" for the best
                  trainer in the stock market industry, while Booming Bulls
                  Academy, India's leading stock market training institute, has
                  been recognised as the "Most Trusted Stock Market Learning
                  Platform in India" at the Atal Achievement Awards in 2022.
                  Having trained over 25,000 students, Anish continues to share
                  his stock market teachings, enabling passionate individuals to
                  become elite traders.
                </p>
                <h5 className="heading-h5 mt-3" style={{ fontWeight: "800" }}>
                  Anish Singh Thakur
                </h5>
                <p className="para color-red">CEO at Booming Bulls Academy</p>
                <div class="flex-1 hide-btn">
                  <button class="button button-mat btn--8">
                    <span class="psuedo-text">
                      {" "}
                      <span>
                        <FaUser />
                      </span>
                      Enroll Now
                    </span>
                  </button>
                </div>

                <div className="shape25">
                  <img src={Shape24} alt="" />
                </div>
                <div className="shape26">
                  <img src={Shape23} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" className="hide-btn" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fill-opacity="1"
            d="M0,256L120,218.7C240,181,480,107,720,106.7C960,107,1200,181,1320,218.7L1440,256L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>

      {/* letest event */}
      <div className="container-section elmt center-box md-box-start">
        <p className="para color-red">LATEST EVENTS</p>
        <h2 className="heading-h2">Upcoming Events</h2>
        <div className="row">
          <div className="col-md-7 col-sm-12">
            <div className="letest-event-section">
              <div>
                <h6 className="heading-h6 text-center">
                  February, 17th <br /> @ 04:00 P.M
                </h6>
              </div>
              <div className="mt-3">
                <h3
                  className="heading-h3 "
                  style={{ textDecoration: "underline" }}
                >
                  Weekend Batch Launch
                </h3>
                <h6 className="heading-h6 my-4">Online Classes</h6>
                <h6 className="heading-h6 start-box">
                  You will learn all these things :
                </h6>
                <ul style={{ listStyleType: "disc" }} className="start-box">
                  <li>Basics of Stock Market</li>
                  <li>Fundamental Analysis Basics</li>
                  <li>Types of Traders & Trading styles</li>
                  <li>Candlestick Patterns</li>
                  <li>Candle Sticks Patterns Advanced</li>
                  <li>Wick vs Body</li>
                  <li>Importance Of Candle Closing</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12">
            <div className="letest-event-section">
              <img src={EventImg} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* How to Apply?*/}
      <div className="container-section mt-100">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="about-welcome-content">
              <p className="para color-red">INFORMATION</p>
              <h2 className="heading-h2">How to Apply?</h2>

              <ul className="information-ul">
                <li>
                  <div className="icon">
                    <FiCheckSquare />
                  </div>
                  <div className="">
                    <h3 className="heading-h3">Download & Read Brochure</h3>
                    <p>
                      Download our{" "}
                      <Link to="" style={{ color: "#fe4a55" }}>
                        Brochure{" "}
                      </Link>
                      , Read it carefully. All the answers to your questions are
                      provided in the brochure.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <PiUserRectangleBold />
                  </div>
                  <div className="">
                    <h3 className="heading-h3">Register & Enrollment</h3>
                    <p>
                      Complete the registration process by paying the amount of
                      registration, and take a screenshot of the successful
                      payment.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <TiBusinessCard />
                  </div>
                  <div className="">
                    <h3 className="heading-h3">Confirmation</h3>
                    <p>
                      Send the screenshot of successful payment on WhatsApp{" "}
                      <Link to="" style={{ color: "#fe4a55" }}>
                        (+91-8130245100)
                      </Link>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <FaBuildingCircleCheck />
                  </div>
                  <div className="">
                    <h3 className="heading-h3">Verification</h3>
                    <p>
                      As soon as your payment is verified, you will receive
                      details of your assigned Relationship Manager
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="letest-event-section">
              <img src={InformationImg} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* download file */}
      <div className="container-section mt-100">
        <div className="download-brochure">
          <div className="hurry">HURRY UP !!</div>
          <span>
            <FaFileDownload />
          </span>
          <p className="color-red">Download Brochure</p>
          <p className="para">
            Click on the button below to download Brochure and get a call back
            from our Academic Counselor
          </p>
          <button className="download-btn">Download Now</button>
        </div>
      </div>
    </>
  );
};

export default About;
