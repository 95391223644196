import React, { useEffect, useState } from 'react'
import MetaData from '../../layout/MetaData'
import CrossShape from "../../asstes/cross-shape.svg";
import { Link } from 'react-router-dom';

import { MdOutlineDateRange } from 'react-icons/md';
import { BiComment } from "react-icons/bi";
import { HiOutlineUsers } from "react-icons/hi";
import Gif from "../../asstes/Leran-Everything-about-trading-from-scretch.gif"
import { BlogData } from './BlogData';
import RightBlog from './RightBlog';
import LeaveReply from './LeaveReply';
const BlogDetails = () => {
    const [blogData, setBlogData] = useState({})
    useEffect(() => {
        BlogData.map((item) => (
            // console.log("object", item)
            setBlogData(item)
        ))
    })
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    return (
        <>
            <MetaData title="Booming Bulls" />
            <MetaData title="Blog details Booming Bulls" />
            {/* banner */}
            <div className="about-banner-section online-course-banner">
                <div className="banner-content blog-detail-content">
                    <h1 className="heading-h2">{blogData.title}: {blogData.question1}</h1>
                    <p className="para">
                        <Link className="" to="/">
                            Home
                        </Link>{" "}
                        / <b>{blogData.title}: {blogData.question1}</b>
                    </p>
                    <div className="cross-shape">
                        <img src={CrossShape} alt="" />
                    </div>
                </div>
            </div>
            {/* blog details */}
            <div className="container-section mt-100">
                <div className="row mt-50">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="news-blog-container blog-details-page">
                            <img src={blogData.img} alt="" />
                            <div className="news-blog-content">
                                <div className="cad-box">
                                    <span>
                                        <BiComment />
                                        No Comments
                                    </span>
                                    <span>
                                        <Link to={"/"}><HiOutlineUsers /> Booming Bulls Academy</Link>
                                    </span>
                                    <span>
                                        <MdOutlineDateRange />{blogData.date}
                                    </span>
                                </div>
                                <h2 className="heading-h2 text-center" style={{ width: "90%", margin: "auto" }}>
                                    <Link>{blogData.title}: {blogData.question1} </Link>
                                </h2>
                                <div className='mt-50'>
                                    <p className="para">
                                        {blogData.ans1}
                                    </p>
                                    <h3 className="heading-h3">{blogData.title}: {blogData.question2}</h3>
                                    <p className="para">   {blogData.ans2}.</p>

                                    <h3 className="heading-h3"> {blogData.question3}</h3>
                                    <p className="para">   {blogData.ans3}.</p>
                                    <h3 className="heading-h3"> {blogData.question4}</h3>
                                    <p className="para">   {blogData.ans4}.</p>
                                </div>
                                <div className="blog-gif ">
                                    <Link to={"/courses/elite-traders-live-mentorship-program-4-0"} target='_blank'>
                                        <img src={Gif} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <LeaveReply />
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <RightBlog />

                    </div>


                </div>
            </div>
        </>
    )
}

export default BlogDetails
