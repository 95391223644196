import React from "react";
import "./home.css";
import { FaUser } from "react-icons/fa";
import Shape1 from "../../asstes/banner-shape12.png";
import Shape2 from "../../asstes/banner-shape13.png";
import Shape3 from "../../asstes/banner-shape14.png";
import { Link } from "react-router-dom";
import EleteTrader from "../../asstes/elite-trader.jpg";
import ICONS from "../../asstes/icon-shape.png";
import { PiBrainThin } from "react-icons/pi";
import { BsShieldCheck } from "react-icons/bs";
import { IoIosDesktop } from "react-icons/io";

const Banner = () => {
  return (
    <>
      <div className="home-banner">
        <div className="home-banner-section">
          <div className="home-banner-container">
            <div className="home-banner-content not"></div>
            <div className="home-banner-content">
              <h1 className="heading-h1">
                Learn to trade in the most simplified way.
              </h1>
              <p className="para">
                Booming Bulls Academy is the best stock market institute that
                provides the most simplified and to-the-point stock market
                course from the scratch for beginners.
              </p>
              <div class="flex-1">
                <button class="button button-mat btn--8">
                  <span class="psuedo-text">
                    {" "}
                    <span>
                      <FaUser />
                    </span>
                    Enroll Now
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="shape1" data-speed="0.06" data-revert="true">
            <img src={Shape1} alt="" />
          </div>

          <div className="shape2" data-speed="0.06" data-revert="true">
            <img src={Shape2} alt="" />
          </div>
          <div className="shape3" data-speed="0.06" data-revert="true">
            <img src={Shape3} alt="" />
          </div>
        </div>

        {/* Revision Classes */}
        <div className="container-section revision">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="learn-scratch-box">
                <div className="icons-box">
                  <span>
                    <PiBrainThin />
                  </span>
                  <img src={ICONS} alt="" />
                </div>
                <h3 className="heading-h3">Learn From Scratch</h3>
                <p className="para">
                  Learn the basics of the stock market, to enhance the
                  understanding of how does the stock market works and progress
                  to an in-depth understanding, acquiring the knowledge and
                  skills needed to thrive in stock market trading.
                </p>
                <Link to="/" className="start-now">
                  Start Now!
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="learn-scratch-box">
                <div className="icons-box">
                  <span>
                    <IoIosDesktop />
                  </span>
                  <img src={ICONS} alt="" />
                </div>
                <h3 className="heading-h3">Revision Classes</h3>
                <p className="para">
                  Over 300+ revision classes to help you cover any topic you
                  miss in your live class. You will never be left behind.
                </p>
                <Link to="/" className="start-now">
                  Start Now!
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="learn-scratch-box">
                <div className="icons-box">
                  <span>
                    <BsShieldCheck />
                  </span>
                  <img src={ICONS} alt="" />
                </div>
                <h3 className="heading-h3">Live Q/A Session</h3>
                <p className="para">
                  We have Live Q/A interaction sessions with Anish Singh Thakur,
                  where students ask their queries directly to him.
                </p>
                <Link to="/" className="start-now">
                  Start Now!
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* stock trader */}
        <div className="container-section stock">
          <div className="row">
            <div className="col-md-8 col-lg-8 col-sm-12">
              <div className="stock-market-course-container">
                <p className="para color-red">
                  INDIA'S NO. 1 STOCK MARKET COURSE
                </p>
                <h2 className="heading-h2">
                  Elite Traders Live Mentorship Program
                </h2>
                <p className="para">
                  The Elite Traders Live Mentorship Program aims to help you
                  learn and apply the knowledge and skills of a professional
                  trader, with the potential for profitable outcomes. It
                  encompasses numerous strategies and price action techniques
                  suitable for various financial markets and trading styles,
                  including Indian stocks, futures & options, the foreign
                  exchange market, and commodities, covering intraday and swing
                  trading.
                </p>
                <p className="para color-red mt-4">
                  Enroll Now by just paying ₹3000
                </p>
                <div class="flex-1">
                  <button class="button button-mat btn--8">
                    <span class="psuedo-text">
                      {" "}
                      <span>
                        <FaUser />
                      </span>
                      Enroll Now
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12">
              <div className="stock-market-course-container">
                <img src={EleteTrader} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
