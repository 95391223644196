import React, { useEffect, useState } from "react";
import CrossShape from "../../asstes/cross-shape.svg";
import "./onlinecourse.css";
import OnlineImg from "../../asstes/online-img.jpg";
import TraderImg from "../../asstes/Elite-Traders-Program-4.0-Vertical.jpg";
import AcademyImg from "../../asstes/why-choose-up.jpg";
import { BsJournalText } from "react-icons/bs";
import { HiLockClosed } from "react-icons/hi";
import { GiTakeMyMoney } from "react-icons/gi";
import { GiMoneyStack } from "react-icons/gi";
import { FaPlay } from "react-icons/fa";
import MetaData from "../../layout/MetaData";
import { useNavigate } from "react-router-dom";

import { LessonData } from "../lessons/LessonData";
import { LessonDataOptions } from "../lessons/LessonData";
const OnlineCourses = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (idx) => {
    setToggleState(idx);
  };
  const navigate = useNavigate();

  let counter = 0;
  for (let i = 0; i < LessonData.length; i++) {
    if (LessonData[i].title) counter++;
  }

  let optionCount = 0;
  for (let i = 0; i < LessonDataOptions.length; i++) {
    if (LessonDataOptions[i].title) optionCount++;
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <MetaData title="Booming Bulls" />
      <MetaData title="Online Course Booming Bulls" />
      {/* banner */}
      <div className="about-banner-section online-course-banner">
        <div className="banner-content">
          <h1 className="heading-h2">
            Elite Traders Live Mentorship Program 4.0
          </h1>
          <p className="para">
            Home <span> / </span> Courses <span> / </span> Stock Market{" "}
            <span> / </span> <b>Elite Traders Live Mentorship Program 4.0</b>
          </p>
          <div className="cross-shape">
            <img src={CrossShape} alt="" />
          </div>
        </div>
      </div>

      {/* img */}
      <div className="online-second-img">
        <div>
          <img src={OnlineImg} alt="" />
        </div>
        <div className="container-section">
          <div className="row mt-100">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="online-course-container">
                <div className="online-block-tabs">
                  <button
                    className={
                      toggleState === 1
                        ? "online-tabs online-tabs-active"
                        : "online-tabs"
                    }
                    onClick={() => toggleTab(1)}
                  >
                    Overview
                  </button>
                  <button
                    className={
                      toggleState === 2
                        ? "online-tabs online-tabs-active"
                        : "online-tabs"
                    }
                    onClick={() => toggleTab(2)}
                  >
                    Curriculum
                  </button>
                  <button
                    className={
                      toggleState === 3
                        ? "online-tabs online-tabs-active"
                        : "online-tabs"
                    }
                    onClick={() => toggleTab(3)}
                  >
                    Instructor
                  </button>
                </div>
                <div className="online-content-tabs ">
                  <div
                    className={
                      toggleState === 1
                        ? "online-content online-active-content mt-50"
                        : "online-content"
                    }
                  >
                    <h3 className="heading-h3">
                      You can attend our 3 days Money-Back Guarantee Class at
                      just
                    </h3>
                    <h3 className="heading-h3" style={{ color: "red" }}>
                      ₹3,000.
                    </h3>
                    <p className="p-lg">
                      You can choose to continue classes or simply take a
                      refund.
                    </p>
                    <hr />
                    <p className="para">
                      In the era of unlimited data connections and work from
                      wherever you are has made life much easier to live and the
                      advantage of this resource is that you can keep your work
                      in progress while you are working on something else. All
                      you need is a management and skill set to manage the whole
                      part of your work instantly. So, why not use this to earn
                      and be what you want? Why not put some effort and be the
                      luxury that you dream of? All you need is to put this
                      effort and discipline in the right direction and you could
                      be buying a luxury while earning the capital you are
                      investing to live that dream. How is that possible? We are
                      here to provide you a skill set which you can use to gain
                      the possibility to reach the life of your dreams.
                    </p>
                    <h3 className="heading-h3">
                      What is Elite Traders Live Mentorship Program?
                    </h3>
                    <p className="para">
                      Trading has always been considered a challenging career.
                      You need proper knowledge if you are willing to be a
                      profitable trader. To help you with that, we have
                      developed a well-developed and perfectly organized Elite
                      Traders Live Mentorship Program that will help you to
                      learn about the stock market from scratch and in the most
                      simplified way.
                    </p>
                    <p className="para">
                      {" "}
                      This program aims to make you an elite trader with perfect
                      knowledge of how and when to enter/exit the trade and book
                      big profits. After completion of this program, you will be
                      able to book consistent profits from the markets if you
                      follow the right process.
                    </p>
                  </div>
                  <div
                    className={
                      toggleState === 2
                        ? "online-content online-active-content"
                        : "online-content"
                    }
                  >
                    <div className="online-lecture-list-container">
                      <h5 className="heading-h5 pe-3 pt-4 pb-2">{counter}</h5>
                      <ul className="lecture-list">
                        {LessonData.map((item, i) => (
                          <li onClick={() => navigate(item.url)}>
                            {item.textIcon}
                            <span>{item.title}</span>
                            <div className="lock">{item.lockIcon}</div>
                          </li>
                        ))}
                      </ul>

                      <div className="d-flex justify-content-between p-4">
                        <h3 className="heading-h3">FEATURE AND OPTIONS</h3>
                        <h5 className="heading-h5 pe-0 pt-4 pb-2">
                          {optionCount}
                        </h5>
                      </div>
                      <ul className="lecture-list">
                        {LessonDataOptions.map((item, i) => (
                          <li>
                            {item.textIcon}
                            <span>{item.title}</span>
                            <div className="lock">{item.lockIcon}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div
                    className={
                      toggleState === 3
                        ? "online-content online-active-content"
                        : "online-content"
                    }
                  >
                    <div className="row">
                      <div className="col-md-4 col-sm-12">
                        <div className="instructor-section">
                          <img src={AcademyImg} alt="" />
                        </div>
                      </div>
                      <div className="col-md-8 col-sm-12">
                        <div className="instructor-section">
                          <h3 className="heading-h3">Booming Bulls Academy</h3>
                          <p className="para">
                            Booming Bulls is an initiative for the young
                            generation who is interested in making more, willing
                            to step forward and be free from financial
                            situations. We provide knowledge that is based on
                            self-learning, experience and theoretical
                            implications on the market.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="online-course-cart-section ">
                <div className="img">
                  <img src={TraderImg} alt="" />
                  <div className="course-preview">
                    <i>
                      {" "}
                      <FaPlay />{" "}
                    </i>
                    <span>Course Preview</span>
                  </div>
                </div>
                <div className="course-price-content">
                  <div className="price">
                    <div>
                      <GiMoneyStack /> <span>Price</span>
                    </div>
                    <div>
                      {" "}
                      <span>₹29,972.00</span>
                    </div>
                  </div>
                  <hr />
                  <button>
                    <GiMoneyStack /> Enroll Now For ₹3,000
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineCourses;
