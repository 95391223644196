import React, { useEffect } from "react";
import FAQ from "./FAQ";
import Newsletter from "../home/Newsletter";
import FaqBanner from "../../asstes/faq-banner.jpg";
import { Link } from "react-router-dom";
import MetaData from "../../layout/MetaData";
const FaqPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <MetaData title="Booming Bulls" />
      <MetaData title="FAQ'S Booming Bulls" />
      <div className="about-banner-section faq-banner-img">
        <div className="banner-content">
          <h1 className="heading-h2">Frequently Asked Questions</h1>
          <p className="para">
            <Link className="color-white" to="/">
              Home
            </Link>{" "}
            / <b>Frequently Asked Questions</b>
          </p>
        </div>
      </div>

      <FAQ />
      <Newsletter />
    </>
  );
};

export default FaqPage;
