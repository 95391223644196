import React, { useEffect } from 'react'
import { MdOutlineDateRange } from 'react-icons/md'
import "./blog.css"
import CrossShape from "../../asstes/cross-shape.svg";
import { Link } from 'react-router-dom'
import UserImg from "../../asstes/user.png";
import MetaData from '../../layout/MetaData';
import { BlogData } from './BlogData';
import RightBlog from './RightBlog';
const Blog = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <>

            <MetaData title="Booming Bulls" />
            <MetaData title="Blog Booming Bulls" />
            <div className="about-banner-section">
                <div className="banner-content">
                    <h1 className="heading-h2">Blog</h1>
                    <p className="para">
                        <Link className="color-white" to="/">
                            Home
                        </Link>{" "}
                        / <b>Blog</b>
                    </p>
                    <div className="cross-shape">
                        <img src={CrossShape} alt="" />
                    </div>
                </div>
            </div>
            {/* NEWS AND BLOGS */}
            <div className="container-section mt-100">

                <div className="row mt-50">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="row">
                            {BlogData.map((item, i) => (



                                <div className="col-md-6 col-sm-12 mb-4" key={i}>
                                    <div className="news-blog-container blog-page">
                                        <img src={item.img} alt="" />
                                        <div className="news-blog-content">
                                            <p>
                                                <Link to={item.url}>{item.title}</Link>
                                            </p>
                                            <h3 className="heading-h3">
                                                <Link to={item.url}> {item.title}: {item.question1}</Link>
                                            </h3>
                                            <p className="para">
                                                {item.title}: {item.ans1.substring(0, 145)}
                                            </p>
                                            <div className="last">
                                                <div>
                                                    <img src={UserImg} alt="" />
                                                    <span>Booming Bulls Academy</span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <MdOutlineDateRange />
                                                    </span>
                                                    <span><Link to={item.url}>{item.date}</Link></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">

                        <RightBlog />
                    </div>


                </div>
            </div>
        </>
    )
}

export default Blog
