import React from "react";
import PopularCardImg from "../../asstes/popular-card-img.jpg";
import { CiViewBoard } from "react-icons/ci";
import { HiOutlineUserGroup } from "react-icons/hi";
import SmallLogo from "../../asstes/small-logo.png";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";

import AhmedabadImg from "../../asstes/Ahmedabad-Hybrid-Center.png";
import BengaluruImg from "../../asstes/Bengaluru.png";
import DelhiImg from "../../asstes/Delhi-Hybrid-Center.png";
import GurgaonImg from "../../asstes/Gurgaon-new.png";
import HydrabadImg from "../../asstes/HYD.png";
import IndoreImg from "../../asstes/Indore.png";
import JaipurImg from "../../asstes/Jaipur-Address.png";
import KolkataImg from "../../asstes/Kolkata-Hybrid.png";
import MumbaiImg from "../../asstes/mumbai.jpeg";
import NagpurImg from "../../asstes/Nagpur.png";
import PuneImg from "../../asstes/Pune.png";
import SuratImg from "../../asstes/Surat.png";
import UserImg from "../../asstes/user.png";
import TradingImg from "../../asstes/Trading-plan-blog.png";

import { MdOutlineDateRange } from "react-icons/md";
const PopularCourse = () => {
  return (
    <>
      <div className="container-section mt-100">
        <div className="popural-course-section">
          <div className="popular-content">
            <p className="color-red">GO AT YOUR OWN PACE</p>
            <h2 className="heading-h2">Our Popular Courses</h2>
            <p className="para">
              Explore all of our courses and pick your suitable ones to enroll
              and start learning with us! We ensure that you will never regret
              it!
            </p>
          </div>
          <div className="row mt-75">
            <div className="col-md-6 col-sm-12">
              <div className="popular-card-box">
                <div className="img-box">
                  <img src={PopularCardImg} alt="" />
                  <span>₹64,900.00</span>
                </div>
                <div className="content-box">
                  <div className="first">
                    <img src={SmallLogo} alt="" />
                    <p className="color-red">
                      <Link className="color-red">Booming Bulls Academy</Link>
                    </p>
                  </div>
                  <h3 className="heading-h3">
                    <Link> Elite Traders Offline Mentorship Program 4.0</Link>
                  </h3>
                  <p className="para">
                    You can attend our 3 days Money-Back Guarantee Class at just
                    ₹5,000. You can choose to continue classes or simply take a
                    refund. In the era of unlimited data connections
                  </p>
                  <div className="last">
                    <span>
                      <CiViewBoard /> 43
                    </span>
                    <span>
                      <HiOutlineUserGroup /> 3500 Students
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="popular-card-box">
                <div className="img-box">
                  <img src={PopularCardImg} alt="" />
                  <span>₹29,972.00</span>
                </div>
                <div className="content-box">
                  <div className="first">
                    <img src={SmallLogo} alt="" />
                    <p className="color-red">
                      <Link className="color-red">Booming Bulls Academy</Link>
                    </p>
                  </div>
                  <h3 className="heading-h3">
                    <Link to="">
                      {" "}
                      Elite Traders Live Mentorship Program 4.0
                    </Link>
                  </h3>
                  <p className="para">
                    You can attend our 3 days Money-Back Guarantee Class at just
                    ₹3,000. You can choose to continue classes or simply take a
                    refund. In the era of unlimited data connections
                  </p>
                  <div className="last">
                    <span>
                      <CiViewBoard /> 43
                    </span>
                    <span>
                      <HiOutlineUserGroup /> 21500 Students
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-sm-12"></div>
          </div>
        </div>
      </div>

      {/* To find out more about our Online Courses */}
      <div className="container-section mt-150">
        <div className="to-find-out-section">
          <h2>
            To find out more about our <span>Online Courses</span>
          </h2>
          <button>
            <FaUser /> Call Us: +91 81302 45100
          </button>
        </div>
      </div>

      {/* Our Hybrid Centers */}
      <div className="container-section mt-100">
        <h2 className="heading-h2 text-center">Our Hybrid Centers</h2>
        <div className="our-hybrid-section mt-50">
          <div className="hybrid-box">
            <img src={AhmedabadImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={BengaluruImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={DelhiImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={GurgaonImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={HydrabadImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={IndoreImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={JaipurImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={KolkataImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={MumbaiImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={NagpurImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={PuneImg} alt="" />
            <button>Know More</button>
          </div>
          <div className="hybrid-box">
            <img src={SuratImg} alt="" />
            <button>Know More</button>
          </div>
        </div>
      </div>

      {/* NEWS AND BLOGS */}
      <div className="container-section mt-100">
        <div className="popular-content">
          <p className="color-red"> NEWS AND BLOGS</p>
          <h2 className="heading-h2">Our Latest Publications</h2>
          <p className="para">
            We always give extra care to our student's skills improvements and
            feel excited to share our latest research and learnings!
          </p>
        </div>
        <div className="row mt-50">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="news-blog-container">
              <img src={TradingImg} alt="" />
              <div className="news-blog-content">
                <p>
                  <Link>TRADING PLAN</Link>
                </p>
                <h3 className="heading-h3">
                  <Link> Creating a Trading Plan: A Step-by-Step Guide</Link>
                </h3>
                <div className="last">
                  <div>
                    <img src={UserImg} alt="" />
                    <span>Booming Bulls Academy</span>
                  </div>
                  <div>
                    <span>
                      <MdOutlineDateRange />
                    </span>
                    <span>2024-01-24</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="news-blog-container">
              <img src={TradingImg} alt="" />
              <div className="news-blog-content">
                <p>
                  <Link>TRADING PLAN</Link>
                </p>
                <h3 className="heading-h3">
                  <Link> Creating a Trading Plan: A Step-by-Step Guide</Link>
                </h3>
                <div className="last">
                  <div>
                    <img src={UserImg} alt="" />
                    <span>Booming Bulls Academy</span>
                  </div>
                  <div>
                    <span>
                      <MdOutlineDateRange />
                    </span>
                    <span>2024-01-24</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="news-blog-container">
              <img src={TradingImg} alt="" />
              <div className="news-blog-content">
                <p>
                  <Link>TRADING PLAN</Link>
                </p>
                <h3 className="heading-h3">
                  <Link> Creating a Trading Plan: A Step-by-Step Guide</Link>
                </h3>
                <div className="last">
                  <div>
                    <img src={UserImg} alt="" />
                    <span>Booming Bulls Academy</span>
                  </div>
                  <div>
                    <span>
                      <MdOutlineDateRange />
                    </span>
                    <span>2024-01-24</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="news-blog-container">
              <img src={TradingImg} alt="" />
              <div className="news-blog-content">
                <p>
                  <Link>TRADING PLAN</Link>
                </p>
                <h3 className="heading-h3">
                  <Link> Creating a Trading Plan: A Step-by-Step Guide</Link>
                </h3>
                <div className="last">
                  <div>
                    <img src={UserImg} alt="" />
                    <span>Booming Bulls Academy</span>
                  </div>
                  <div>
                    <span>
                      <MdOutlineDateRange />
                    </span>
                    <span>2024-01-24</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="news-blog-container">
              <img src={TradingImg} alt="" />
              <div className="news-blog-content">
                <p>
                  <Link>TRADING PLAN</Link>
                </p>
                <h3 className="heading-h3">
                  <Link> Creating a Trading Plan: A Step-by-Step Guide</Link>
                </h3>
                <div className="last">
                  <div>
                    <img src={UserImg} alt="" />
                    <span>Booming Bulls Academy</span>
                  </div>
                  <div>
                    <span>
                      <MdOutlineDateRange />
                    </span>
                    <span>2024-01-24</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="news-blog-container">
              <img src={TradingImg} alt="" />
              <div className="news-blog-content">
                <p>
                  <Link>TRADING PLAN</Link>
                </p>
                <h3 className="heading-h3">
                  <Link> Creating a Trading Plan: A Step-by-Step Guide</Link>
                </h3>
                <div className="last">
                  <div>
                    <img src={UserImg} alt="" />
                    <span>Booming Bulls Academy</span>
                  </div>
                  <div>
                    <span>
                      <MdOutlineDateRange />
                    </span>
                    <span>2024-01-24</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularCourse;
