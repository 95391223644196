import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./layout/Navbar";
import HomePage from "./component/home/HomePage";
import Footer from "./layout/Footer";
import AboutUs from "./component/about/AboutUs";
import Testimonial from "./component/about/Testimonial";
import FaqPage from "./component/about/FaqPage";
import OnlineCourses from "./component/online-courses/OnlineCourses";
import AhmedabadCenter from "./component/offline-center/AhmedabadCenter";
import { ContactData } from "./utils/ContactData";

import Resources from "./component/resources/Resources";
import Lesson from "./component/lessons/Lesson";
import Blog from "./component/blog/Blog";
import BlogDetails from "./component/blog/BlogDetails";
import ContactPage from "./component/contact/ContactPage";


import "./responsive.css"
function App() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/testimonials" element={<Testimonial />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/courses">
          <Route
            path="elite-traders-live-mentorship-program-4-0"
            element={<OnlineCourses />}
          />
        </Route>

        {/* start center location */}

        {ContactData.map((data, i) => (
          <Route
            key={i}
            path={data.url}
            element={
              <AhmedabadCenter
                title={data.title}
                className={data.className}
                contactdetails={data.Address}
                number={data.Number}
              />
            }
          />
        ))}
        {/* end center location */}
        <Route path="/resources" element={<Resources />} />
        <Route path="/lesson" element={<Lesson />} />
        <Route path="/courses/lesson" element={<Lesson />} />
        <Route path={`/courses/lesson/:slug`} element={<Lesson />} />
        {/* clog */}
        <Route path="blog" element={<Blog />} />
        <Route path="blog-details" element={<BlogDetails />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
