import React, { useEffect, useState } from "react";
import Student1 from "../../asstes/student1.jpeg";
import Student2 from "../../asstes/student2.jpeg";
import Student3 from "../../asstes/student3.jpeg";
import { FaMapMarkerAlt, FaUser } from "react-icons/fa";

import { GiCheckMark } from "react-icons/gi";
import { FaPhoneSquareAlt } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";

const OurStudent = ({ number, contactdetails }) => {
  const [input, setInput] = useState("");
  const handleChange = (e) => {
    setInput(e.target.value);
  };
  useEffect(() => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="container-section">
        <p className="color-red text-center">TESTIMONIALS</p>
        <h2 className="heading-h2 text-center">
          What Our Students Say About Us
        </h2>
        <div className="row mt-50">
          <div className="col-md-6 col-lg-4 col-sm-12 mb-4">
            <div className="our-student-box">
              <div className="img">
                <img src={Student1} alt="" />
              </div>
              <p className="para">
                My experience with Booming Bulls academy was really good.
                Classes were conducted very neatly and systematically by Anish
                sir and his team. This course gave me the confidence to make
                money in the market. I have already recommended this course to
                my friends and peers.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-sm-12 mb-4">
            <div className="our-student-box">
              <div className="img">
                <img src={Student2} alt="" />
              </div>
              <p className="para">
                Anish Sir's explaining style is unique and simple. From trading
                psychology to meditation, he teaches everything. I suggest
                everyone interested in trading should join Booming Bulls. It's
                worth the money.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-sm-12 mb-4">
            <div className="our-student-box">
              <div className="img">
                <img src={Student3} alt="" />
              </div>
              <p className="para">
                My experience with Booming Bulls was incredible. I am glad to
                have a mentor like Anish Sir. The best part about this course
                is- It's not just only about the stock market, but it also
                covers important aspects like trading psychology, personality
                development, etc. I recommend this course to those who want to
                learn to trade.
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-3">
          <button className="enroll-now-btn ">
            {" "}
            <FaUser /> View More
          </button>
        </div>
      </div>

      <div className="container-section mt-50">
        <h2 className="heading-h2 text-center">
          WANT TO JOIN THE PROGRAM BUT NOT ABLE TO PAY AT ONCE?
        </h2>
        <div className="row mt-4">
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="d-flex align-items-center">
              {" "}
              <span className="color-tomato me-3">
                <GiCheckMark />
              </span>
              <p className="para">
                No Worries! We have a solution for you. Getting Stock Market
                education is more flexible now! You can now pay in Easy EMI's
                for upto six months!
              </p>
            </div>
            <div className="d-flex ">
              <span className="color-tomato me-3">
                <GiCheckMark />
              </span>
              <p className="para">
                Attend three demo classes for just Rs. 5000. Book your seat now!
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-12"></div>
        </div>
      </div>

      {/* contact form */}
      <div className="container-section mt-100 mb-100">
        <h2 className="heading-h2 text-end hide-btn" style={{ marginRight: "8%" }}>
          Fill Up The Form Now
        </h2>
        <div className="row mt-50">
          <div className="col-lg-4 col-lg-6-custom col-md-6 col-sm-12">
            <div className="contact-details">
              <p className="color-red">Contact Details</p>
              <h2 className="heading-h2">Get in Touch</h2>
              <p className="para">
                Have any queries? or want to know more? Contact us and we will
                be happy to help.
              </p>
              <ul className="mt-50">
                <li>
                  <h3 className="heading-h3">Our Address</h3>
                  <div className="title">
                    <div className="icons">
                      <FaMapMarkerAlt />
                    </div>
                    <p className="para ">
                      {/* 303-304, Shivalik 2 Nr. Shivranjani Cross Road, 132 Feet
                      Ring Rd, Jodhpur Village, Ahmedabad, Gujarat 380015 */}
                      {contactdetails}
                    </p>
                  </div>
                </li>

                <li className="mt-5">
                  <h3 className="heading-h3">Contact</h3>
                  <div className="title">
                    <div className="icons">
                      <FaPhoneSquareAlt />
                    </div>
                    <p className="para">
                      Mobile:{" "}
                      <a href="tel:+ 91 81302-45100" className="color-red">
                        {/* + 91 81302-45100 */}
                        {number}
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 hide-btn"></div>
          <div className="col-lg-5 col-lg-6-custom col-md-6 col-sm-12">
            <h2 className="heading-h2  shows-btn" >
              Fill Up The Form Now
            </h2>
            <div className="fill-form-container ">
              <div className="form-input">
                <div className="input name-field">
                  <div className="name">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      name=""
                      value={input}
                      onChange={handleChange}
                      id="input"
                    />
                    <span>Fist name</span>
                  </div>
                  <div className="name mt-3">
                    <label htmlFor="" className="mb-4"></label>
                    <input
                      type="text"
                      name=""
                      // value={input}
                      // onChange={handleChange}
                      id="input"
                    />
                    <span>Last name</span>
                  </div>
                </div>
                <div className="input">
                  <label htmlFor="">Email</label>
                  <input type="email" name="" id="input" />
                </div>
                <div className="input">
                  <label htmlFor="">Phone</label>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    country={"in"}
                    value={input}
                    onChange={() => setInput(input)}
                  />
                </div>
                <div className="input">
                  <label htmlFor="">City</label>
                  <input type="text" name="" id="input" />
                </div>
                <div className="input">
                  <label htmlFor="">
                    How much capital you are planning to trade with? *
                  </label>
                  <select id="input">
                    <option value="">-Select-</option>
                    <option value="">0 to 50,000</option>
                    <option value="">50,000 to 2,00,000</option>
                    <option value="">2,00,000 to 5,00,000</option>
                    <option value="">Above 5,00,000</option>
                  </select>
                </div>
                <div className="input">
                  <label htmlFor="">Which batch do you want to join ? *</label>
                  <select id="input">
                    <option value="">-Select-</option>
                    <option value="">February 2024</option>
                    <option value="">March 2024</option>
                    <option value="">April 2024</option>
                  </select>
                </div>

                <button className="enroll-now-btn mt-5 px-5">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurStudent;
