import React, { useEffect } from "react";
import Banner from "./Banner";
import About from "./About";
import PopularCourse from "./PopularCourse";
import Contact from "./Contact";
import Newsletter from "./Newsletter";
import MetaData from "../../layout/MetaData";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <MetaData title="Booming Bulls" />
      <MetaData title="Booming Bulls" />
      <Banner />
      <About />
      <PopularCourse />
      <Contact />
      <Newsletter />
    </div>
  );
};

export default HomePage;
